import { useNavigate } from "react-router-dom";
import {    
  MDBBtn,
  MDBTable, 
  MDBTableHead, 
  MDBTableBody
 } from 'mdb-react-ui-kit';

export default function UserEvents(user){

	const events = user.my_events
	const navigate = useNavigate();
  
  if(events.length > 0){
    const eventList = events.map((event,index) => {
      return <tr key={index}>
							<td>
								<MDBBtn onClick={() => {navigate("/event/" + event.event_key_id)}} color='link' rounded size='sm'>
									{event.event_name}
								</MDBBtn>
							</td>
							<td><p className='fw-bold mb-1'>{event.start_date}</p></td>
              <td><p className='fw-bold mb-1'>{event.end_date}</p></td>
              <td><p className='mb-1'>{event.omgangs_nr}</p></td>
							<td><p className='mb-1'>{event.klass_name_display}</p></td>
            </tr>
    });
    return <div>
          <MDBTable align='middle'>
            <MDBTableHead>
              <tr>
                <th scope='col'>Namn</th>
                <th scope='col'>Start-datum</th>
                <th scope='col'>Slut-datum</th>
                <th scope='col'>Omgångsnummer</th>
                <th scope='col'>Klass</th>
              </tr>
            </MDBTableHead>
            <MDBTableBody>{eventList}</MDBTableBody>
          </MDBTable>
          </div>
          ;
  }

  return <p align='middle'>Användaren är inte anmäld till några tävlingar.</p>;
  
}