import React from 'react';
import { Page, Text, Image, View, Document, StyleSheet } from '@react-pdf/renderer';
import img from "../css/images/Viltmal_Nyhetsbild_auto_2.png"

// Create styles
const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  title: {
    fontSize: 24,
    textAlign: 'center',
    fontFamily: 'Times-Roman'
  },
  author: {
    fontSize: 12,
    textAlign: 'center',
    marginBottom: 40,
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
    fontFamily: 'Times-Roman'
  },
  text: {
    margin: 12,
    fontSize: 14,
    textAlign: 'justify',
    fontFamily: 'Times-Roman'
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: 'center',
    color: 'grey',
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
});

// Create Document Component
export const MyDocument = () => (
	<Document>
	<Page style={styles.body}>
		<Text style={styles.header} fixed>
			~ Exempeldokument ~
		</Text>
		<Text style={styles.title}>Skyttesport</Text>
		<Text style={styles.author}>Test Testsson</Text>
		<Text style={styles.subtitle}>
			Underrubrik
		</Text>
		<Text style={styles.text}>
		Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas molestie ac tellus quis vehicula. 
		Cras varius lorem a metus blandit finibus. Proin iaculis eros ut porta cursus. 
		Pellentesque eget mi molestie, laoreet ligula ac, rutrum augue. Sed pulvinar 
		dictum sapien eget posuere. Vestibulum ut tempor felis, non malesuada metus. 
		Curabitur pellentesque sapien eget nunc porttitor dignissim. In sit amet elementum orci, 
		quis ornare urna. Nam nec lorem dolor. Fusce quis mi et dui faucibus molestie. 
		Duis posuere faucibus magna ac aliquet. 
		</Text>
		<Text style={styles.text}>
		Sed nec scelerisque nisi. Donec orci ante, cursus quis neque vitae, condimentum scelerisque tortor. 
		Cras faucibus sit amet magna vitae pulvinar. Aliquam ut nisl aliquam, gravida augue ac, 
		feugiat tellus. Pellentesque scelerisque dictum venenatis. Sed maximus blandit eros, 
		id sodales augue pretium sed. Cras at ultrices elit. Suspendisse eget turpis sed tellus 
		molestie accumsan ac nec enim. 
		</Text>
		<Text style={styles.text}>
		Suspendisse a volutpat tellus. Vivamus cursus leo in leo porttitor, sit amet maximus metus consequat. 
		Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. 
		Donec finibus a nisi efficitur efficitur. Nunc commodo dui vitae nibh placerat, varius ultrices odio iaculis. 
		Nam venenatis mi non ante luctus rutrum. Quisque ut ipsum vestibulum, tristique orci non, suscipit ex. Curabitur 
		eget volutpat est. Nullam fringilla sem arcu, sed lacinia sapien interdum vitae. Praesent elementum ligula metus, 
		quis mollis ante consequat in. Nunc placerat tincidunt risus et suscipit. 
		Cras et nisi elit. Mauris vel vulputate nulla, dapibus gravida turpis. 
		</Text>
		<Text style={styles.text}>
		Maecenas sem ligula, fermentum in nunc sit amet, pharetra mattis augue. Quisque nec convallis dolor. 
		Nullam facilisis nisi ac nulla gravida, a vehicula eros pellentesque. Nulla sit amet cursus nunc, vel 
		congue justo. Fusce congue consequat tellus, non placerat odio facilisis malesuada. Aenean quam purus, 
		cursus vitae felis et, commodo efficitur urna. Maecenas euismod, nulla vel tempor venenatis, sapien nisi sodales mauris, 
		ut efficitur nunc justo eu ex. Sed aliquet dictum ipsum nec placerat. Sed pretium neque vel lectus lobortis, ut commodo nulla porta. 
		</Text>
		<Text style={styles.subtitle} break>
			Chapter II: Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit...
		</Text>
		<Image
			style={styles.image}
			src={img}
		/>
		<Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
			`${pageNumber} / ${totalPages}`
		)} fixed />
	</Page>
</Document>
);