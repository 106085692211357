import {    
  MDBContainer,
  MDBSpinner} from 'mdb-react-ui-kit';
import { add_license, fetch_get, update_processed_order } from './service/PaymentRequest';
import { useNavigate } from "react-router-dom";
import { fetch_json } from '../../../services/common';

async function capturePayment(){
  
  const order_key = localStorage.getItem("order-key");
  const payment_key = await fetch_json("app/pay/data/" + order_key)
  const obj = await fetch_get(payment_key.data.payment_key,"");
  const metadata = await fetch_get(payment_key.data.payment_key,"metadata");
  
  const users = metadata.data.metadata.data;
  await update_processed_order(order_key,obj.data.paymentOrder.status);
  if(obj.data.paymentOrder.status == "Paid"){
    users.forEach(user => {
      add_license(user);
    });
    return true;
  }
  return false;

}

export default function ProcessPayment() {

  const navigate = useNavigate();

  if(capturePayment()){
    setTimeout(() => {
      navigate("/successful-payment");
    }, 2000)
  }
  else{
    setTimeout(() => {
      navigate("/payment-error");
    }, 2000)
  }
      //
   return (
    <MDBContainer className="py-4">
        <div className='d-flex justify-content-center'>
          <MDBSpinner role='status'>
            <span className='visually-hidden'>Loading...</span>
          </MDBSpinner>
        </div>
        <div className='d-flex justify-content-center'>
          Behandlar betalning...
        </div>
    </MDBContainer>
   );
}