import React, { useState, useRef, useEffect } from 'react';
import {
  MDBSideNav,
  MDBSideNavMenu,
  MDBSideNavItem,
  MDBSideNavLink,
  MDBSideNavCollapse,
  MDBIcon,
  MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCheckbox,
  MDBCol,
  MDBDatepicker,
  MDBSelect,
  MDBInputGroup,
  MDBRadio,
  MDBInput,
  MDBValidationItem,
  MDBValidation,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBCardHeader,
  MDBAccordion,
  MDBAccordionItem,
  MDBCardSubTitle,
  MDBDatatable,
  MDBBadge,
  MDBModal,
  MDBModalHeader,
  MDBModalDialog,
  MDBModalContent,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBTextArea,
  MDBSwitch,
  MDBPopconfirm,
  MDBPopconfirmMessage
} from 'mdb-react-ui-kit';
import { useParams, useNavigate } from "react-router-dom";
import { fetch_get, fetch_post, fetch_delete } from "../../../services/common";
import { BorderCardBody } from '../../modules/BorderCol';


const customWeekdaysNarrow = ['S', 'M', 'T', 'O', 'T', 'F', 'L'];
const customMonthsFull = ['Januari', 'Februari', 'Mars', 'April', 'Maj', 'Juni', 'Juli', 'Augusti', 'September', 'Oktober', 'November', 'December',];
const customWeekdaysShort = ['Sön', 'Mån', 'Tis', 'Ons', 'Tor', 'Fre', 'Lör'];
const customMonthsShort = ['Jan', 'Feb', 'Mar', 'Apr', 'Maj', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'];


export default function EventEditLag({ params }) {

  const { id } = useParams();
  const [event_key_id, setEventKeyID] = useState(id);
  const [data_all, setData] = useState([]);
  const [lov_types, setLovTypesData] = useState([]);
  const [lov_klasser, setLovKlasserData] = useState([]);
  const [teams_config, setTeamsConfigData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [lag_checked, setLagCheckedData] = useState(false);

  const [basicModal, setBasicModal] = useState(false);
  const toggleOpen = () => setBasicModal(!basicModal);

  const [formValue, setFormValue] = useState({
    action: 'event_team_config',
    key_id: id,
    team_id: 0,
    db_def_event_team_type_id: "",
    db_name: "",
    db_number_of_team_members: "",
    db_startavgift: "",
    klasser: []
  });

  useEffect(() => {

    fetch_get('app/event/form_data/' + id)
      .then(data => {
        setData(data.data);
        setLovTypesData(data.data.lov_team_types);
        setLovKlasserData(data.data.lov_team_klasser);
        setTeamsConfigData(data.data.teams_config);
        if (data.data.lag_tavling == 1) {
          setLagCheckedData(true);
        } else {
          setLagCheckedData(false);
        }

        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setLoading(false);
      });
  }, [loading]);

  const onChange = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };

  const mainStyles = {
    rubriker: {
      height: 20,
      position: 'absolute',
      top: '-10px',

    },
  };

  const advancedData = {
    columns: [
      { label: ' ', field: 'actions', sort: false },
      { label: 'Typ av lagtävling', field: 'team_type', sort: false },
      { label: 'Namn', field: 'name', sort: true },

      { label: 'Antal skyttar', field: 'number_of_team_members', sort: false },
      { label: 'Startavgift (kr)', field: 'startavgift', sort: false },
      { label: 'Ingående klasser', field: 'klasser', sort: true },
    ],
    rows: teams_config.map((row) => {
      return {
        ...row,
        actions: (
          <>


            <MDBBtn
              outline size='sm'
              floating
              className='call-btn me-1'
              onClick={() => onedit(row.id)}
            >
              <MDBIcon far icon="edit" />
            </MDBBtn>
            <MDBPopconfirm
              outline
              size='sm'
              floating
              className='call-btn me-1'
              color="danger"
              modal
              btnChildren={<MDBIcon far icon="trash-alt" />}
              cancelBtnText='Avbryt'
              onConfirm={() => ondelete(row.id)}
            >
              <MDBPopconfirmMessage
                icon={<MDBIcon className='me-2' far icon="trash-alt" />}

              >
                Ta bort lagtävling: {row.name}?
              </MDBPopconfirmMessage>
            </MDBPopconfirm>

          </>
        ),
      };
    })
  }


  const onSubmitEvent = async (e) => {
    e.preventDefault();

    if (e.target.checkValidity()) {
      setBasicModal(false);
      const resJson = await fetch_post('app/event/do_submit/' + formValue.key_id, formValue);
      setLoading(true);
    } else {
      console.log('form not valid');
    }

  };

  const ondelete = async (e) => {

    console.log('delete', e);
    const delete_obj =
    {
      action: 'event_team_config_delete',
      key_id: id,
      team_id: e
    };

    const resJson = await fetch_post('app/event/do_submit/' + formValue.key_id, delete_obj);
    setLoading(true);

  };

  const onedit = async (e) => {

    const resJson = await fetch_get('app/event/team_data/' + e);

    console.log('edit', e, resJson);
    setFormValue(
      {
        action: 'event_team_config',
        key_id: id,
        team_id: e,
        db_def_event_team_type_id: resJson.data.def_event_team_type_id,
        db_name: resJson.data.name,
        db_number_of_team_members: resJson.data.number_of_team_members,
        db_startavgift: resJson.data.startavgift,
        klasser: resJson.data.klasser
      });

    setBasicModal(true);

  };

  const onnew = async () => {

    console.log('new');
    setFormValue(
      {
        action: 'event_team_config',
        key_id: id,
        team_id: 0,
        db_def_event_team_type_id: "",
        db_name: "",
        db_number_of_team_members: "",
        db_startavgift: "",
        klasser: []
      });

    setBasicModal(true);

  };

  return (
    <>

      <MDBContainer className="py-4">


        <MDBRow className='g-3 py-3'>
          <MDBCol className='col-md-12 col-sm-12'>
            <MDBRow className='g-3'>
              <MDBCard border='primary' className='p-2'>

                <BorderCardBody
                                headline="Lagtävling"
                                info_id="modal_5"   //
                >
                  {/* <MDBBadge light style={mainStyles.rubriker}>Lagtävling</MDBBadge> */}
                  <MDBRow>
                    <div className="d-grid gap-2 d-md-block">
                      <MDBBtn rounded onClick={onnew}><MDBIcon className='me-2' fas icon="plus" />Lägg till ny lagtävling</MDBBtn>
                    </div>
                    <MDBDatatable
                      data={advancedData}
                      pagination={false}
                      noFoundMessage='Ingen lagtävling skapad...'
                      hover
                    />

                  </MDBRow>

                </BorderCardBody>
              </MDBCard>
            </MDBRow>
          </MDBCol>
        </MDBRow>




        <MDBModal open={basicModal} staticBackdrop setopen={setBasicModal} tabIndex='-1'>
          <MDBModalDialog>
            <MDBModalContent>
              <MDBValidation onSubmit={onSubmitEvent}>
                <MDBModalHeader>
                  <MDBModalTitle>Ny/Ändra Lagtävling</MDBModalTitle>
                  <MDBBtn className='btn-close' type='reset' color='none' onClick={toggleOpen}></MDBBtn>
                </MDBModalHeader>
                <MDBModalBody>

                  <MDBRow>
                    <MDBValidationItem className='py-2' invalid feedback='Ange typ av lagtävling.'>
                      {basicModal && (

                        <MDBSelect
                          name='db_def_event_team_type_id'
                          id='form_db_def_event_team_type_id'
                          label='Typ av lagtävling'
                          preventFirstSelection
                          data={lov_types}
                          required
                          validation
                          value={formValue.db_def_event_team_type_id}
                          validFeedback={false}
                          invalidFeedback
                          optionHeight={50}
                          onValueChange={(e) => setFormValue({ ...formValue, 'db_def_event_team_type_id': e.value })}
                        />
                      )}
                    </MDBValidationItem>
                    <MDBValidationItem className='py-2' invalid feedback='Ange lagtävlingens namn.'>
                      {basicModal && (
                        <MDBInput
                          name='db_name'
                          id='form_db_name'
                          label={<>Lagtävlingens namn</>}
                          type='text'
                          required
                          value={formValue.db_name}
                          onChange={onChange}
                        />
                      )}
                    </MDBValidationItem>
                    <MDBValidationItem className='py-2 col-6' invalid feedback='Ange antal skyttar i laget.'>
                      {basicModal && (
                        <MDBInput
                          name='db_number_of_team_members'
                          id='form_db_number_of_team_members'
                          label={<>Antal skyttar i laget</>}
                          type='number'
                          required
                          value={formValue.db_number_of_team_members}
                          onChange={onChange}
                        />
                      )}
                    </MDBValidationItem>
                    <MDBValidationItem className='py-2 col-6' invalid feedback='Ange startavgift.'>
                      {basicModal && (
                        <MDBInput
                          name='db_startavgift'
                          id='form_db_startavgift'
                          label={<>Startavgift (kr)</>}
                          type='number'
                          required
                          value={formValue.db_startavgift}
                          onChange={onChange}
                        />
                      )}
                    </MDBValidationItem>
                    <MDBValidationItem className='py-2 col-10' invalid feedback='Ange ingående klasser.'>
                      {basicModal && (
                        <MDBSelect
                          name='klasser'
                          id='form_klasser'
                          displayedLabels={8}
                          label='Ingående klasser'
                          optionsSelectedLabel='klasser valda'
                          data={lov_klasser}
                          multiple
                          validation
                          validFeedback
                          value={formValue.klasser}
                          invalidFeedback
                          optionHeight={40}
                          selectAllLabel="Välj alla"
                          visibleOptions={6}
                          onValueChange={(e) => setFormValue({ ...formValue, 'klasser': e.map(a => a.value) })}
                        />
                      )}
                    </MDBValidationItem>
                  </MDBRow>


                </MDBModalBody>

                <MDBModalFooter>
                  <MDBBtn color='secondary' type='reset' onClick={toggleOpen}>
                    Avbryt
                  </MDBBtn>
                  <MDBBtn type='submit'>Spara</MDBBtn>
                </MDBModalFooter>
              </MDBValidation>
            </MDBModalContent>
          </MDBModalDialog>
        </MDBModal>



      </MDBContainer >

    </>
  );
}

