import { useState } from 'react';
import {
  MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsContent,
  MDBTabsPane,
  MDBRow,
  MDBCol,
	MDBTypography
} from 'mdb-react-ui-kit';

export default function SideMenu(menus) {
  const [verticalActive, setVerticalActive] = useState(menus.menu[0].name);

  const handleVerticalClick = (value) => {
    if (value === verticalActive) {
      return;
    }

    setVerticalActive(value);
  };

  return (
    <>
      <MDBRow>
        <MDBCol size='2'>
					<div className='text-center'>
						{menus.heading ? <h5>{menus.heading}</h5> : null}
					</div>
          <MDBTabs className='flex-sm-column text-center'>
						{menus.menu.map(menu => {
							return 	<MDBTabsItem className='bg-light border border-white border-3' key={menu.name}>
												<MDBTabsLink onClick={() => handleVerticalClick(menu.name)} active={verticalActive === menu.name}>
													{menu.name}
												</MDBTabsLink>
											</MDBTabsItem>
						})}
          </MDBTabs>
        </MDBCol>
        <MDBCol size='10'>
          <MDBTabsContent>
						{menus.menu.map(menu => {
							return 	<MDBTabsPane key={menu.name} open={verticalActive === menu.name}>
												{menu.content}
											</MDBTabsPane>
						})}
          </MDBTabsContent>
        </MDBCol>
      </MDBRow>
    </>
  );
}