import React, { useEffect,useState } from 'react';
import {    
  MDBBtn,
	MDBTooltip,
	MDBDatatable,
	MDBIcon,
	MDBModal,
	MDBModalBody,
	MDBModalContent,
	MDBModalTitle,
	MDBModalFooter,
	MDBModalHeader,
	MDBModalDialog,
	MDBPopconfirm,
	MDBPopconfirmMessage,
	MDBAlert
 } from 'mdb-react-ui-kit';
 import { fetch_get } from '../../../services/common';
 import { EditSkyttekort } from './utils/EditSkyttekort';

export default function editSkyttekortModal(params){
	
	const [loading, setLoading] = useState(false);
	const user = params.sentUser[0];
	const [emptyMsg, setEmptyMsg] = useState('Skyttekort saknas');
	const [skyttekortDeletedToast, setSkyttekortDeletedToast] = useState(false)
	const [skyttekortActivatedToast, setSkyttekortActivatedToast] = useState(false)
	const [skyttekortErrorToast, setSkyttekortErrorToast] = useState(false)

	const [asyncData, setAsyncData] = useState({
    columns: [
      { label: 'Nummer', field: 'nummer' },
			{ label: 'Gren/diciplin', field: 'gren' },
			{ label: 'Datum', field: 'datum'},
			{ label: 'Status', field: 'status' },
			{ label: 'Verktyg', field: 'verktyg', sort: false, width: 50}
    ],
    rows: [],
  });

	const editSkyttekort = (status,skyttekortID) => {

		EditSkyttekort(status, user.key_id,skyttekortID).then(obj => {
			if(obj.status == "OK"){
				if(status == "DELETED"){
					setSkyttekortDeletedToast((prev) => !prev);
				} else {
					setSkyttekortActivatedToast((prev) => !prev);
				}
			} else {
				setSkyttekortErrorToast((prev) => !prev)
			}
		});
		
	}

	useEffect(() => {
		setLoading(true)
		fetch_get("app/user/skyttekort/" + user.key_id)
			.then((data) =>
				setTimeout(() => {
					if(data.data.length == 0){
						setEmptyMsg("Användaren saknar skyttekort");
					}
					setAsyncData({
						columns: asyncData.columns,
						rows: data.data.map((skyttekort) => ({
							...skyttekort,
							nummer: (
								<p>{skyttekort.skyttekort_nummer}</p>
							),
							gren: (
								<p>{skyttekort.gren_name}</p>
							),
							datum: (
								<p>{skyttekort.skyttekort_datum}</p>
							),
							status: (
								<p>{skyttekort.status}</p>
							),
							
							verktyg: (<>
													{skyttekort.status == 'ACTIVE' ? 
													<MDBTooltip tag='a' title='Radera skyttekort'>
														<MDBPopconfirm modal btnChildren={<MDBIcon far icon="trash-alt" size='sm' />}  color='none' onConfirm={() => editSkyttekort("DELETED",skyttekort.id)} style={{color: '##0065A4'}} tag='a' className='m-1'>
															<MDBPopconfirmMessage>Avsluta skyttekort för användare {user.first_name} {user.last_name}</MDBPopconfirmMessage>
														</MDBPopconfirm>
													</MDBTooltip> : 
													<MDBTooltip tag='a' title='Aktivera skyttekort'>
														<MDBPopconfirm modal btnChildren={<MDBIcon fas icon="check" size='sm'/>}  color='none' onConfirm={() => editSkyttekort("ACTIVE",skyttekort.id)} style={{color: '##0065A4'}} tag='a' className='m-1'>
															<MDBPopconfirmMessage>Aktivera skyttekort för användare {user.first_name} {user.last_name}</MDBPopconfirmMessage>
														</MDBPopconfirm>
													</MDBTooltip>}
													
												</>
							)
						})),
					},
					setLoading(false));
				}, 3000)
			);
	}, [skyttekortDeletedToast,skyttekortActivatedToast,user,asyncData.columns]);

    return <>
						<MDBAlert
							color='primary'
							autohide
							position='top-right'
							delay={4000}
							appendToBody
							open={skyttekortDeletedToast}
							onClose={() => setSkyttekortDeletedToast(false)}
						>
							Skyttekort har avslutats för {user.first_name} {user.last_name}.
						</MDBAlert>
						<MDBAlert
							color='primary'
							autohide
							position='top-right'
							delay={4000}
							appendToBody
							open={skyttekortActivatedToast}
							onClose={() => setSkyttekortActivatedToast(false)}
						>
							Skyttekort har aktiverats för {user.first_name} {user.last_name}. 
						</MDBAlert>
						<MDBAlert
							color='danger'
							autohide
							position='top-right'
							delay={4000}
							appendToBody
							open={skyttekortErrorToast}
							onClose={() => setSkyttekortErrorToast(false)}
						>
							Något gick fel vid ändring av skyttekort.
						</MDBAlert>
						<MDBModal onClose={params.close} open={params.show} tabIndex='-1'>
							<MDBModalDialog size="lg">
								<MDBModalContent>
									<MDBModalHeader>
										<MDBModalTitle>{user.first_name + " " + user.last_name}</MDBModalTitle>
										<MDBBtn className='btn-close' color='none' onClick={params.close}></MDBBtn>
										</MDBModalHeader>
											<MDBModalBody>
												<MDBDatatable data={asyncData} isLoading={loading} loadingMessage={"Hämtar skyttekort..."} noFoundMessage={emptyMsg}/>
											</MDBModalBody>
										<MDBModalFooter>
										<MDBBtn color='secondary' onClick={params.close}>
											Stäng
										</MDBBtn>
									</MDBModalFooter>
								</MDBModalContent>
							</MDBModalDialog>
						</MDBModal>
          </>
  
}