import React, { useState, useEffect } from 'react';
import {
	MDBBadge,
	MDBCol,
	MDBIcon,
	MDBBtn,
	MDBPopover,
	MDBPopoverHeader,
	MDBPopoverBody,
	MDBModal,
	MDBModalDialog,
	MDBModalContent,
	MDBModalHeader,
	MDBModalTitle,
	MDBModalFooter,
	MDBModalBody,
	MDBTooltip,
	MDBCardBody,

} from 'mdb-react-ui-kit';
import { fetch_get } from "../../services/common";

//<MDBCardBody>

export const BorderCol = (props) => {
	const [basicModal, setBasicModal] = useState(false);
	const toggleOpen = () => setBasicModal(!basicModal);

	const mainStyles = {
		rubriker: {
			height: 20,
			position: 'absolute',
			top: '-10px',

		},
		button: {
			padding: '5px'
		}
	};

	return (<>

		<MDBCol {...props}>

			<InfoObject {...props} />

			<MDBBadge light className="position-absolute" style={mainStyles.rubriker}>{props.headline}</MDBBadge>

			{props.children}
		</MDBCol>
	</>);
}

export const BorderCardBody = (props) => {
	const [basicModal, setBasicModal] = useState(false);
	const toggleOpen = () => setBasicModal(!basicModal);

	const mainStyles = {
		rubriker: {
			height: 20,
			position: 'absolute',
			top: '-10px',

		},
		button: {
			padding: '5px'
		}
	};

	return (<>

		<MDBCardBody {...props}>

			<InfoObject {...props} />

			<MDBBadge light className="position-absolute" style={mainStyles.rubriker}>{props.headline}</MDBBadge>

			{props.children}
		</MDBCardBody>
	</>);
}

const InfoObject = (props) => {
	const [basicModal, setBasicModal] = useState(false);
	const [loaded, setLoaded] = useState(false);
	const toggleOpen = () => {
		setBasicModal(!basicModal);
		if (!loaded) {
			if (!basicModal) {
				// Föregående första gången, då hämtar vi data
				// Sätter sedan att data är hämta
				const text_id = props.info_id.split("_");
				fetch_get('app/list/info-text/' + text_id[1])
					.then(data => {
						setInfoText(data.data.text);
						setLoaded(true);
					})
					.catch(error => {
						console.error('Error fetching data:', error);
						setLoaded(true);
					});


			}
		}
	};

	const doOpen = () => {
		if (props.info_text) {
			setInfoText(props.info_text);
			setLoaded(true);
		} else {
			if (!loaded) {
				// Föregående första gången, då hämtar vi data
				// Sätter sedan att data är hämta
				const text_id = props.info_id.split("_");
				fetch_get('app/list/info-text/' + text_id[1])
					.then(data => {
						setInfoText(data.data.text);
						setLoaded(true);
					})
					.catch(error => {
						console.error('Error fetching data:', error);
						setLoaded(true);
					});

			}
		}
	}

	const [infotext, setInfoText] = useState("Laddar.......");



	const mainStyles = {
		rubriker: {
			height: 20,
			position: 'absolute',
			top: '-10px',

		},
		button: {
			padding: '5px'
		}
	};

	return (<>

		{props.info_id &&
			<>
				{props.info_id.startsWith("popover") &&
					<MDBPopover
						dismiss
						tag='a'
						style={{ cursor: 'pointer' }}
						btnClassName="position-absolute  top-0 start-100 translate-middle rounded outline"
						btnChildren={<MDBIcon fas icon="info-circle" color='warning' />}
						onOpen={doOpen}
					>
						<MDBPopoverHeader>Info: {props.headline}</MDBPopoverHeader>
						<MDBPopoverBody dangerouslySetInnerHTML={{ __html: infotext }}>
						</MDBPopoverBody>
					</MDBPopover>
				}
				{props.info_id.startsWith("modal") &&
					<>
						<MDBBtn
							tag='a'
							size='sm'
							onClick={toggleOpen}
							className="position-absolute  top-0 start-100 translate-middle rounded outline btn bg-transparent shadow-0"
						>
							<MDBIcon fas icon="info-circle" color='info' size='lg' />
						</MDBBtn>
						<MDBModal open={basicModal} setOpen={setBasicModal} tabIndex='-1'>
							<MDBModalDialog centered size="lg">
								<MDBModalContent>
									<MDBModalHeader>
										<MDBModalTitle>Info: {props.headline} ({props.info_id})</MDBModalTitle>
										<MDBBtn className='btn-close' color='none' onClick={toggleOpen}></MDBBtn>
									</MDBModalHeader>
									<MDBModalBody dangerouslySetInnerHTML={{ __html: infotext }}></MDBModalBody>

									<MDBModalFooter>
										<MDBBtn color='secondary' onClick={toggleOpen}>
											Stäng
										</MDBBtn>
										{/* <MDBBtn>Save changes</MDBBtn> */}
									</MDBModalFooter>
								</MDBModalContent>
							</MDBModalDialog>
						</MDBModal>
					</>
				}
				{props.info_id.startsWith("tooltip") &&
					<>
						<span className="position-absolute top-0 start-100 translate-middle">
							<MDBTooltip
								tag='a'
								title={<div dangerouslySetInnerHTML={{ __html: infotext }}
									
								></div>}
								onOpen={doOpen}
							>
								<MDBIcon fas icon="info-circle" color='muted'

								/>
							</MDBTooltip>
						</span>
					</>
				}
			</>
		}

	</>);
}