import React,{useState} from 'react';
import { useLoaderData, Link } from "react-router-dom";
import { fetch_json } from "../../../services/common";
import {
  MDBContainer,
  MDBCard,
  MDBBreadcrumb,
	MDBBreadcrumbItem,
  MDBCardBody,
  MDBCardText,
  MDBCardTitle,
  MDBRow,
  MDBCol,
  MDBCardImage
} from 'mdb-react-ui-kit';
import jwt_decode from "jwt-decode";

export async function loader({ params }) {
  
  const data = localStorage.getItem("token");
  var decoded = "";
  if (data) {
    decoded = jwt_decode(data);
  }
    const obj = await fetch_json("app/user/all/" + decoded.kid);
    return obj;
}


export default function DistriktHome() {

  const loadObject = useLoaderData();

  return (
    <>
      <MDBContainer className="py-4">    
        <MDBRow> 
          <MDBCol md='6'>
            <MDBCard className='img-fluid hover-shadow rounded-4 rounded border border-secondary' style={{ maxWidth: '540px' }}>
              <Link style={{ color: '#000' }} to={"user-admin/" + loadObject.data.forening_id}>
                <MDBRow className='g-0'>
                  <MDBCol md='5'>
                    <MDBCardImage src='https://cdn.pixabay.com/photo/2017/11/10/05/46/group-2935521_960_720.png' alt='...' fluid />
                  </MDBCol>
                  <MDBCol md='7'>
                    <MDBCardBody>
                      <MDBCardTitle>Distrikt</MDBCardTitle>
                      <MDBCardText>
                        Här kan du administrera distrikt.....
                      </MDBCardText>
                    </MDBCardBody>
                  </MDBCol>
                </MDBRow>
              </Link>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </>
  );
}