import React, { useState, useRef, useEffect } from 'react';
import {
  MDBSideNav,
  MDBSideNavMenu,
  MDBSideNavItem,
  MDBSideNavLink,
  MDBSideNavCollapse,
  MDBIcon,
  MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCheckbox,
  MDBCol,
  MDBDatepicker,
  MDBSelect,
  MDBInputGroup,
  MDBRadio,
  MDBInput,
  MDBValidationItem,
  MDBValidation,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBCardHeader,
  MDBAccordion,
  MDBAccordionItem,
  MDBCardSubTitle,
  MDBDatatable,
  MDBBadge,
  MDBModal,
  MDBModalHeader,
  MDBModalDialog,
  MDBModalContent,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBTextArea,
  MDBSwitch,
  MDBPopconfirm,
  MDBPopconfirmMessage,
  MDBNavbar,
  MDBTooltip,
  MDBDropdown,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBDropdownToggle

} from 'mdb-react-ui-kit';
import { useParams, useNavigate, NavLink } from "react-router-dom";
import { fetch_get, fetch_post, fetch_delete } from "../../../services/common";
import { extExcelExport } from '../../documents/ExcelExport';
import { BorderCol } from '../../modules/BorderCol';

export default function EventEditDeltagare({ params }) {
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const [current_forening, setForening] = useState(null);
  const [test, setTest] = useState("3");
  const [templov, setTempLov] = useState([]);
  const [formValue, setFormValue] = useState({});
  const [klass_list, setKlassList] = useState(null);
  const [lov_foreningar, setDataForeningar] = useState([]);
  const [lov_team, setLovTeam] = useState([]);
  const [lov_klasser, setDataLovKlasser] = useState([]);
  const [medlemmar, setDataMedlemmar] = useState({
    columns: [],
    rows: []
  });
  const [loading, setLoading] = useState(true);
  const [checked, setChecked] = useState(false);




  let formObjekt = {};

  useEffect(() => {

    fetch_get('app/event/all/' + id)
      .then(data => {
        const data_start = data.data.deltagare;
        const data_tmp = [];
        let data_out = [];

        if (current_forening) {
          console.log('current_forening', current_forening);
          data_start.map(o => (o.forening_id === current_forening ? data_tmp.push(o) : o));
          //data_out = data_tmp;
          console.log('data_tmp', data_tmp);
          data_out = data_tmp;
        } else {
          data_out = data_start;
        }


        const data_lov_klasser = data.data.lov_klasser;
        data_lov_klasser.unshift({ "value": " ", "text": "-" });


        data_out.map((k_item, k) => {
          //formObjekt["klass" + k_item.id] = data_lov_klasser.map(o => (o.value == k_item.event_klass_id ? { ...o, defaultSelected: true } : o));
          formObjekt["prispengar" + k_item.id] = k_item.prispengar || 0;
        });

        setFormValue(formObjekt);


        /*
      const deltagare = data_out.map((row) => {

        row.lov_k = data_lov_klasser.map(o => (o.value === row.event_klass_id ? { ...o, defaultSelected: true } : o));
        console.log('row', row);

        return {
          ...row,
          delete_button: (
            <MDBPopconfirm
              outline
              size='sm'
              floating
              
              color="danger"
              modal
              btnChildren={<MDBIcon far icon="trash-alt" color="danger" size='sm' />}
              cancelBtnText='Avbryt'
              onConfirm={() => ondeleteDeltagare(row.id)}
              cancelBtnClasses='btn-secondary'
              //btnClassName='btn-link btn-sm'
              title={"Ta bort deltagare"}
            >
              <MDBPopconfirmMessage
                icon={<MDBIcon className='me-2' far icon="trash-alt" color='danger' />}
                size='lg'
              >
                Ta bort deltagare: <br /><br />{row.full_name}<br />{row.forening_name}?
              </MDBPopconfirmMessage>
            </MDBPopconfirm>

          ),
          select_klass: (
            <>
              <MDBSelect
                size='sm'
                data={row.lov_k}
                preventFirstSelection
                onValueChange={(e) => onKlassChanged(row.id, e.value)}
              />




            </>
          ),
          pris_pengar: (
            <>
              <MDBInput
                id={'id_prispengar' + row.id}
                name={'prispengar' + row.id}
                type='text'
                size='sm'
                value={formValue['prispengar' + row.id]}
                onChange={onChange}
              />
            </>
          ),
        };
      });
*/
        setData2(data_start);
        setData(data_out);
        setDataLovKlasser(data_lov_klasser);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setLoading(false);
      });


    fetch_get('app/list/lov-foreningar')
      .then(data => {
        setDataForeningar(data.data);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setLoading(false);
      });

    fetch_get('app/event/lov-team-config/' + id)
      .then(data => {
        data.data.unshift({ "value": "", "text": "-" });
        //console.log('team-lov', data.data);
        setLovTeam(data.data);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setLoading(false);
      });

  }, [loading, current_forening]);

  const lov_team_nr = [{ text: '1' }, { text: '2' }, { text: '3' }, { text: '4' }, { text: '5' }, { text: '6' }, { text: '7' }, { text: '8' }, { text: '9' }];

  const advancedData = {
    columns: [
      // { label: ' ', field: 'delete_button', sort: false, width: 55 },
      { label: ' ', field: 'delete_button', sort: false, width: 70 },
      { label: 'Status', field: 'status', sort: true, width: 120 },
      { label: 'Förening', field: 'forening_name_t', sort: true, width: 200 },
      { label: 'Namn', field: 'full_name_t', sort: true, width: 200 },
      { label: 'Klass', field: 'select_klass', sort: true, width: 120 },
      // { label: 'Lag', field: 'select_team', sort: true, width: 120 },
      // { label: '#', field: 'select_team_nr', sort: true, width: 100 },
      { label: 'Prispengar', field: 'pris_pengar', sort: true, width: 80 },
      // { label: 'xx', field: 'aa', sort: true, width: 80 },
    ],
    rows: data.map((row) => {

      row.lov_k = lov_klasser.map(o => (o.value === row.event_klass_id ? { ...o, defaultSelected: true } : o));
      row.lov_team_id = lov_team.map(o => (o.value === row.event_team_id ? { ...o, defaultSelected: true } : o));
      row.lov_team_nr = lov_team_nr.map(o => (o.text === row.event_team_id_nr ? { ...o, defaultSelected: true } : o));



      return {
        ...row,
        forening_name_t: (
          <span title={row.forening_name}>{row.forening_name}</span>
        ),
        full_name_t: (
          <span title={row.full_name}>
            {row.full_name}
          </span>
        ),
        delete_button: (
          <MDBPopconfirm
            //outline
            //size='sm'
            //floating
            style={{ padding: '5px' }}
            color="none"
            modal
            btnChildren={<MDBIcon far icon="trash-alt" color="danger" size='sm' />}
            //<MDBIcon far icon="trash-alt" color="danger" size='sm' />
            cancelBtnText='Avbryt'
            onConfirm={() => ondeleteDeltagare(row.id)}
            cancelBtnClasses='btn-secondary'
            btnClassName='btn btn-link'
            title={"Ta bort deltagare"}
          >
            <MDBPopconfirmMessage
              icon={<MDBIcon className='me-2' far icon="trash-alt" color='danger' />}
              size='lg'
            >
              Ta bort deltagare: <br /><br />{row.full_name}<br />{row.forening_name}?
            </MDBPopconfirmMessage>
          </MDBPopconfirm>

        ),
        select_klass: (
          <>
            {row.event_klass_id === " " && (
              <MDBSelect
                size='sm'
                style={{ backgroundColor: '#ffb3b3', fontWeight: 400 }}
                data={row.lov_k}
                preventFirstSelection
                onValueChange={(e) => onKlassChanged(row.id, e.value)}
              />
            )}
            {row.event_klass_id !== " " && (
              <MDBSelect
                size='sm'
                data={row.lov_k}
                preventFirstSelection
                onValueChange={(e) => onKlassChanged(row.id, e.value)}
              />
            )}

          </>
        ),
        select_team: (
          <>
            <MDBSelect
              size='sm'
              data={row.lov_team_id}
              // preventFirstSelection
              onValueChange={(e) => onTeamChanged(row.id, e.value)}
            />
          </>
        ),
        select_team_nr: (
          <>
            <MDBSelect
              size='sm'
              data={row.lov_team_nr}
              preventFirstSelection
              onValueChange={(e) => onTeamNrChanged(row.id, e.text)}
            />
          </>
        ),
        pris_pengar: (
          <>
            <MDBInput
              id={'id_prispengar' + row.id}
              name={'prispengar' + row.id}
              type='number'
              size='sm'
              value={formValue['prispengar' + row.id]}
              onChange={(e) => onChange(e)}
              onBlur={(e) => onBlur_prispengar(row.id, e)}
            />
          </>
        ),
        status: (
          <>
          {!row.participant_ok.participant_ok && <>
            <MDBIcon title={row.participant_ok.text} color='warning' fas icon="exclamation-triangle" /></>}
          <MDBBadge color={row.event_order_status_id == 10 ? 'warning' : row.event_order_status_id == 20 ? 'success' : ''} pill>
            {row.event_order_status_name}
          </MDBBadge>
          </>
        )
      };
    })
  };

  const mainStyles = {
    rubriker: {
      height: 20,
      // position: 'absolute',
      top: '-10px',

    },
    button: {
      padding: '5px'
    }
  };

  const onadddeltagare = async (user_key_id, forening_id) => {

    console.log('onadddeltagare', user_key_id, forening_id);
    const form_params = {
      "action": "event_add_deltagare",
      "admin": true,
      "user_key_id": user_key_id,
      "db_forening_id": forening_id
    };



    const resJson = await fetch_post('app/event/do_submit/' + id, form_params);
    setLoading(true);

  };

  const onBlur_prispengar = async (deltagar_id, e) => {

    console.log('onBlur_prispengar', e.target.value, e);
    const form_params = {
      "action": "event_set_deltagar_data",
      "deltagar_id": deltagar_id,
      "db_prispengar": e.target.value
    };

    const resJson = await fetch_post('app/event/do_submit/' + id, form_params);
    setLoading(true);

  };

  const onChange = async (e) => {

    console.log('onchange', e.target.name, e.target.value);
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };

  const onTeamChanged = async (deltagar_id, lov_id) => {

    // setTest(klass_id);

    console.log('onTeamChanged', deltagar_id, lov_id, data);

    const form_params = {
      "action": "event_set_deltagar_data",
      "deltagar_id": deltagar_id,
      "db_event_team_id": lov_id
    };

    const resJson = await fetch_post('app/event/do_submit/' + id, form_params);
    setLoading(true);

  };

  const onTeamNrChanged = async (deltagar_id, lov_id) => {

    // setTest(klass_id);

    console.log('onTeamNrChanged', deltagar_id, lov_id);

    const form_params = {
      "action": "event_set_deltagar_data",
      "deltagar_id": deltagar_id,
      "db_event_team_id_nr": lov_id
    };

    const resJson = await fetch_post('app/event/do_submit/' + id, form_params);
    setLoading(true);

  };

  const onKlassChanged = async (deltagar_id, klass_id) => {

    // setTest(klass_id);

    console.log('onKlassChanged', deltagar_id, klass_id, data);

    const form_params = {
      "action": "event_set_deltagar_klass",
      "deltagar_id": deltagar_id,
      "db_event_klass_id": klass_id
    };

    const resJson = await fetch_post('app/event/do_submit/' + id, form_params);
    setLoading(true);

  };


  const ondeleteDeltagare = async (deltagar_id) => {

    console.log('ondeleteDeltagare', id);
    const form_params = {
      "action": "event_delete_deltagare",
      "deltagar_id": deltagar_id
    };



    const resJson = await fetch_post('app/event/do_submit/' + id, form_params);
    setLoading(true);

  };

  const changeChecked = () => {
    let curr_checked = !checked;
    setChecked(curr_checked);
    console.log('current_forening', current_forening);
    changeForening({ value: current_forening }, curr_checked);

  }

  const changeForening = (val, checked_in) => {
    console.log(val);

    setForening(val.value);

    fetch_get('app/forening/user-list-event/' + val.value + '/' + id)
      .then(data => {
        const medl_tmp = data.data.map((row) => {
          return {
            ...row,
            add_button: (
              <>

                {row.name} {!row.participant_ok.participant_ok && <>
                  <MDBIcon title={row.participant_ok.text} color='warning' fas icon="exclamation-triangle" /></>}
                <MDBBtn
                  outline size='sm'
                  floating
                  disabled={false} // Om vi vill låsa så att de utan lic/skyttekort inte ska kunna läggas till i startlistan, använd denna {!row.participant_ok}
                  className='call-btn me-4 float-end'
                  onClick={() => onadddeltagare(row.key_id, row.forening_id)}
                >
                  <MDBIcon fas icon="angle-right" />
                </MDBBtn>
              </>
            ),
            test: (
              <>
                <MDBInput label='Example label' id='form1' type='text' size='sm' />
              </>
            ),
          };
        });

        let tmp;
        if (!checked_in) {
          tmp = medl_tmp.filter(e => e.participant_ok.participant_ok);
        } else {
          tmp = medl_tmp;
        }

        const medl = tmp;

        setDataMedlemmar({
          columns: [
            { label: '', field: 'add_button', sort: false },
          ],
          rows: medl,
        });
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });

  }

  const exportToJson = e => {
    e.preventDefault()
    downloadFile({
      data: JSON.stringify(data2),
      fileName: 'Deltagare' + id + '.json',
      fileType: 'text/json',
    })
  }

  const exportToCsv = e => {
    e.preventDefault()

    // Headers for each column
    let headers = ['Id,Name']

    // Convert users data to a csv
    let usersCsv = data2.reduce((acc, user) => {
      const { id, full_name } = user
      acc.push([id, full_name].join(','))
      return acc
    }, [])

    downloadFile({
      data: [...headers, ...usersCsv].join('\n'),
      fileName: 'Deltagare.csv',
      fileType: 'text/csv',
    })
  }

  const columns = ["Namn", "Efternamn"]

  return (
    <>
      <MDBContainer className="py-4">

        <MDBNavbar light bgColor='light'>

          <MDBSelect
            label="Välj förening"
            data={lov_foreningar}
            search
            preventFirstSelection
            clearBtn
            visibleOptions={8}
            searchLabel='Sök förening'
            onValueChange={(e) => changeForening(e, checked)}
          //size='sm'

          />

          {/* <MDBBtn floating onClick={exportToJson}><MDBIcon fas icon='table'/></MDBBtn> */}
          <MDBDropdown>
            <MDBDropdownToggle color='secondary'>Skriv ut / Export</MDBDropdownToggle>
            <MDBDropdownMenu>
              <MDBDropdownItem link color='light'><MDBIcon fas icon="file-pdf" className='me-2' />Deltagarlista (PDF)</MDBDropdownItem>
              <MDBDropdownItem link onClick={() => extExcelExport(data2, null, "Deltagare_" + id)}><MDBIcon fas icon="file-excel" className='me-2' />Deltagarlista (Excel)</MDBDropdownItem>
              <MDBDropdownItem link onClick={exportToCsv}>Sius: shooter.csv</MDBDropdownItem>
              <MDBDropdownItem link onClick={exportToCsv}>Megalink: export.txt</MDBDropdownItem>
              <MDBDropdownItem link onClick={exportToJson}>Deltagarlista (JSON)</MDBDropdownItem>
            </MDBDropdownMenu>
          </MDBDropdown>

        </MDBNavbar>

        <MDBRow className='g-3 py-3'>
          <MDBCol className='col-md-12 col-sm-12'>
            <MDBRow className='g-3'>

              <BorderCol
                headline="Föreningsmedlemmar"
                info_id="modal_3"   //
                //info_text="aa<b>a</b>aa<br />xxxx"  // info_text om man inte vill hämta från databasen
                className='col-md-3 col-sm-12 square border border-primary rounded-6 p-4 mx-1 position-relative'
              >

                <MDBCheckbox
                  name='flexCheck'
                  value=''
                  id='flexCheckDefault'
                  label='Visa alla'
                  checked={checked}
                  onChange={() => changeChecked()}
                />

                <MDBDatatable
                  //className='pb-4'
                  //search
                  bordered
                  sm
                  striped
                  hover
                  maxHeight='560px'
                  sortField='full_name'
                  pagination={false}
                  entries={1000}
                  noFoundMessage='Välj förening...'
                  loaderClass='Laddar...'
                  ofText='av'
                  rowsText='Rader per sida:'
                  data={medlemmar}
                />
              </BorderCol>

              <BorderCol
                headline="Deltagare"
                info_id="modal_1"
                className='col-md-8 col-sm-12 square border border-primary rounded-6 p-4 mx-1 position-relative'
              >


                <MDBDatatable

                  search
                  bordered
                  searchLabel="Sök (fritext sökning)"
                  sm
                  striped
                  hover
                  sortField='forening_name, full_name'
                  pagination={false}
                  entries={1000}
                  noFoundMessage='Inga deltagare inlagda...'
                  loaderClass='Laddar...'
                  ofText='av'
                  rowsText='Rader per sida:'
                  data={advancedData}
                /*                   format={(field, value) => {
                                    if (field === 'select_klass') {
                                      console.log('value', value);
                                      return { backgroundColor: '#E3F2FD', fontWeight: 400 };
                                    }
                                  }} */
                />
              </BorderCol>


            </MDBRow>
          </MDBCol>
        </MDBRow>


      </MDBContainer >
    </>
  );
}


const downloadFile = ({ data, fileName, fileType }) => {
  // Create a blob with the data we want to download as a file
  const blob = new Blob([data], { type: fileType })
  // Create an anchor element and dispatch a click event on it
  // to trigger a download
  const a = document.createElement('a')
  a.download = fileName
  a.href = window.URL.createObjectURL(blob)
  const clickEvt = new MouseEvent('click', {
    view: window,
    bubbles: true,
    cancelable: true,
  })
  a.dispatchEvent(clickEvt)
  a.remove()
}

