import React, { useState, useRef, useEffect } from 'react';
import { MDBFilters } from 'mdb-react-filters';
import {
  MDBContainer,
  MDBTableBody,
  MDBTable,
  MDBDatatable,
  MDBTabsLink,
  MDBTabs,
  MDBTabsItem,
  MDBTabsContent,
  MDBTabsPane,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBIcon,
  MDBBadge,
  MDBCardBody,
  MDBNavbar,
  MDBBtn,
  MDBAlert,
  MDBPopconfirm,
  MDBPopconfirmMessage,
  MDBFile,
  MDBInputGroup,
  MDBSelect,
  MDBInput
} from 'mdb-react-ui-kit';
import {
  Outlet,
  NavLink,
  Link,
  useLoaderData,
  useParams,
  Form,
  redirect,
  //useNavigation,
  //useSubmit,
} from "react-router-dom";
import { fetch_get, fetch_post, fetch_post_document } from "../../../services/common";
import EventEditLag from "./EventEditLag";
import EventEditStartlista from "./EventEditStartlista";
import EventEditStartlista2 from "./EventEditStartlista2";
import EventEditDeltagare from "./EventEditDeltagare";






export const loader = async ({ params }) => {
  const resJson = await fetch_get('app/event/all/' + params.id);
  return resJson;
  //return [];
};


const mainStyles = {
  rubriker: {
    height: 20,
    position: 'absolute',
    top: '-10px',

  },
  label: {
    fontsize: '0.8rem',
    height: 30,
  }
};

export default function EventsPage() {
  const { id } = useParams();
  const loadObject = useLoaderData();
  const [data, setData] = useState(loadObject);
  //const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [basicActive, setBasicActive] = useState('tab0');

  useEffect(() => {



    fetch_get('app/event/all/' + id)
      .then(data => {
        console.log('data', data)
        setData(data);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setLoading(false);
      });


  }, [loading]);

  // *****************************


  const onsend_for_approval = async () => {

    console.log('onsend_for_approval');
    const form_params = {
      "action": "event_change_status",
      "db_status_id": 100
    };



    const resJson = await fetch_post('app/event/do_submit/' + id, form_params);
    setLoading(true);

  };

  return (
    <>


      <MDBContainer className="py-4">

        <h1 className="fs-1">Tävling - {data.data.name} <span className="fs-4">({data.data.start_date})</span></h1>

        {(data.data.status_id < 100 && data.data.sportgrenar.length > 0) && (
          <>
            <MDBAlert open className='w-100' color='info'>
              <span className='pe-2'>Skicka in tävlingen för godkännande:</span>

              <MDBPopconfirm
                // outline
                size='sm'
                // floating
                // className='call-btn me-1'
                color="info"
                modal
                btnChildren='Skicka in'

                cancelBtnText='Avbryt'
                onConfirm={() => onsend_for_approval()}
              >
                <MDBPopconfirmMessage
                  icon={<MDBIcon className='me-2' far icon="share-square" />}

                >
                  Vill du skicka in tävlingen för godkännande?
                </MDBPopconfirmMessage>
              </MDBPopconfirm>
            </MDBAlert>
          </>
        )}

        {(data.data.status_id < 100 && data.data.sportgrenar.length === 0) && (
          <>
            <MDBAlert open className='w-100' color='danger'>
              För att skicka in tävlingen för godkännande behöver ni lägga till sportgrenar!
            </MDBAlert>
          </>
        )}

        <EventTab data_arr={data} />

      </MDBContainer>

    </>
  );
}


function EventTab(data_arr) {
  const [basicActive, setBasicActive] = useState('tab0');
  const [data, setProps] = useState(data_arr);

  useEffect(() => {

    setProps(data_arr);
  }, [data_arr]);

  const handleBasicClick = (value) => {
    if (value === basicActive) {
      return;
    }

    setBasicActive(value);
  };

  return (
    <>
      <MDBTabs className='mb-3'>
        <MDBTabsItem>
          <MDBTabsLink onClick={() => handleBasicClick('tab0')} active={basicActive === 'tab0'}>
            Översikt
          </MDBTabsLink>
        </MDBTabsItem>
        <MDBTabsItem>
          <MDBTabsLink onClick={() => handleBasicClick('tab1')} active={basicActive === 'tab1'}>
            Sportgrenar
          </MDBTabsLink>
        </MDBTabsItem>
        <MDBTabsItem>
          <MDBTabsLink onClick={() => handleBasicClick('tab_lag')} active={basicActive === 'tab_lag'}>
            Lagtävling
          </MDBTabsLink>
        </MDBTabsItem>
        {data.data_arr.data.event_type_id == '100' && (
          <MDBTabsItem>
            <MDBTabsLink onClick={() => handleBasicClick('tab_serie')} active={basicActive === 'tab_serie'}>
              Serietävling
            </MDBTabsLink>
          </MDBTabsItem>

        )}


        <MDBTabsItem>
          <MDBTabsLink onClick={() => handleBasicClick('tab2')} active={basicActive === 'tab2'}>
            Deltagare
          </MDBTabsLink>
        </MDBTabsItem>
        {/* Inbjudningstävling */}
        {data.data_arr.data.event_type_id == '200' && (
          <>
            <MDBTabsItem>
              <MDBTabsLink onClick={() => handleBasicClick('tab_startlista')} active={basicActive === 'tab_startlista'}>
                Omgångar
              </MDBTabsLink>
            </MDBTabsItem>
            <MDBTabsItem>
              <MDBTabsLink onClick={() => handleBasicClick('tab_startlista2')} active={basicActive === 'tab_startlista2'}>
                Startlista
              </MDBTabsLink>
            </MDBTabsItem>
          </>
        )}
        {/* Serietävling */}
        {data.data_arr.data.event_type_id == '100' && (
          <MDBTabsItem>
            <MDBTabsLink onClick={() => handleBasicClick('tab_startlista')} active={basicActive === 'tab_startlista'}>
              Omgångar
            </MDBTabsLink>
          </MDBTabsItem>

        )}
        <MDBTabsItem>
          <MDBTabsLink onClick={() => handleBasicClick('tab3')} active={basicActive === 'tab3'}>
            Dokument
          </MDBTabsLink>
        </MDBTabsItem>
      </MDBTabs>

      <MDBTabsContent>
        <MDBTabsPane open={basicActive === 'tab0'}><Overview data={data.data_arr.data} /></MDBTabsPane>
        <MDBTabsPane open={basicActive === 'tab1'}><Sportgrenar key_id={data.data_arr.data.key_id} arr={data.data_arr.data.sportgrenar} /></MDBTabsPane>
        <MDBTabsPane open={basicActive === 'tab_lag'}><EventEditLag /></MDBTabsPane>
        <MDBTabsPane open={basicActive === 'tab_serie'}><h3>Administration serietävling</h3></MDBTabsPane>
        {basicActive === 'tab_startlista' && (
          <MDBTabsPane open={basicActive === 'tab_startlista'}><EventEditStartlista /></MDBTabsPane>
        )}
        {basicActive === 'tab_startlista2' && (
          <MDBTabsPane open={basicActive === 'tab_startlista2'}><EventEditStartlista2 /></MDBTabsPane>
        )}
        {basicActive === 'tab2' && (
          <MDBTabsPane open={basicActive === 'tab2'}><EventEditDeltagare /></MDBTabsPane>
        )}
        <MDBTabsPane open={basicActive === 'tab3'}><Dokument doc_arr={data.data_arr.data.documents} /></MDBTabsPane>
      </MDBTabsContent>
    </>
  );
}


function Overview(overview_data) {
  console.log('oo', overview_data);
  const [data, setProps] = useState(overview_data);

  useEffect(() => {

    setProps(overview_data);
  }, [overview_data]);

  const advancedData = {
    columns: [
      { label: 'Namn', field: 'full_name', sort: true },
      { label: 'Förening', field: 'forening_name', sort: true },
      { label: 'Klass', field: 'klass_name_display', sort: true },
    ],
    rows: data.deltagar_arr,
  };

  return (
    <>
      {/* <MDBRow>
          <MDBCol size='4'>
            <span className="d-inline-flex flex-column px-2 align-self-start">
              <span>
                <span class="badge badge-light float-start">Tävling</span>
              </span>
              <span className='ps-2'>Tester</span>
              <span>
                <span class="badge badge-light float-start">Typ av tävling</span>
              </span>
              <span className='ps-2'>Inbjudningstävling</span>              
            </span>
          </MDBCol>

        </MDBRow> */}

      <MDBNavbar light bgColor='light'>
        <MDBContainer tag="form" fluid className='justify-content-start'>
          <NavLink
            className='btn btn-primary btn-sm me-2'
            as={Link}
            to={'/event/' + data.data.key_id + '/edit'}
          >
            <MDBIcon fas icon="edit" className='me-2' />Ändra
          </NavLink>
          <NavLink
            className='btn btn-primary btn-sm me-2'
            as={Link}
            to={'/event/' + data.data.key_id + '/betalning'}
          >
            <MDBIcon fas icon="edit" className='me-2' />Betalnnig
          </NavLink>
          {/* <MDBBtn className='me-2' type='button' size="sm" onClick={console.log('fffff')}>
            
            <MDBIcon className='me-2' fas icon="edit" />Ändra
          </MDBBtn>
          <MDBBtn outline color="secondary" size="sm" type='button'>
            Smaller button
          </MDBBtn> */}



        </MDBContainer>
      </MDBNavbar>


      <MDBRow className='g-3 py-3'>
        <MDBCol className='col-md-6 col-sm-12'>
          <MDBRow className='g-3 pe-2'>
            <MDBCard border='primary' className='p-2'>
              <MDBCardBody>
                <MDBBadge light style={mainStyles.rubriker}>Tävling</MDBBadge>

                <MDBTable small>

                  <MDBTableBody>
                    <tr>
                      <th>Tävling</th>
                      <td>{data.data.name}</td>
                    </tr>
                    <tr>
                      <th>Typ</th>
                      <td>{data.data.event_type}</td>
                    </tr>
                    <tr>
                      <th>Kategori</th>
                      <td>{data.data.kategori}</td>
                    </tr>
                    <tr>
                      <th>Arrangör</th>
                      <td>{data.data.arrangorer.map((object, i) => <span key={object.forening_id} className="pe-4">{object.name}</span>)}</td>
                    </tr>
                    <tr>
                      <th>Status</th>
                      <td>
                        <MDBBadge color={data.data.status_color} pill light>
                          {data.data.status || "Utkast (ej inskickad)"}
                        </MDBBadge>
                        {/* {data.data.status || "Utkast (ej inskickad)"} */}
                      </td>
                    </tr>
                    <tr>
                      <th>Tävlingsdatum</th>
                      <td>{data.data.start_date} till {data.data.end_date}</td>
                    </tr>
                    <tr>
                      <th>Publiceringsdatum</th>
                      <td>{data.data.publicering_date || 'Vid godkänd tävling'}</td>
                    </tr>
                    <tr>
                      <th>Öppen för anmälan</th>
                      <td>{data.data.anmalan_start_date || 'Ej angivet'}</td>
                    </tr>
                    <tr>
                      <th>Sista anmälningsdagen</th>
                      <td>{data.data.anmalan_start_date || 'Ej angivet'}</td>
                    </tr>
                  </MDBTableBody>
                </MDBTable>
              </MDBCardBody>
            </MDBCard>
          </MDBRow>
        </MDBCol>
        <MDBCol className='col-md-6 col-sm-12'>
          <MDBCol className='pb-3'>
            <MDBRow className='g-3 pe-2'>
              <MDBCard border='primary' className='p-2'>
                <MDBCardBody>
                  <MDBBadge light style={mainStyles.rubriker}>Plats</MDBBadge>

                  <MDBTable small>

                    <MDBTableBody>
                      <tr>
                        <th>Plats för tävlingen</th>
                        <td><pre style={{ fontSize: 14.4 }}>{data.data.plats}</pre></td>
                      </tr>
                      <tr>
                        <th>Hemsida</th>
                        <td>{data.data.hemsida || "Ej angivet"}</td>
                      </tr>
                    </MDBTableBody>
                  </MDBTable>
                </MDBCardBody>
              </MDBCard>
            </MDBRow>
          </MDBCol>

          <MDBCol className='pb-3'>
            <MDBRow className='g-3 pe-2'>
              <MDBCard border='primary' className='p-2'>
                <MDBCardBody>
                  <MDBBadge light style={mainStyles.rubriker}>Kontaktuppgifter</MDBBadge>

                  <MDBTable small>

                    <MDBTableBody>
                      <tr>
                        <th>Namn</th>
                        <td>{data.data.kontakt_first_name}</td>
                      </tr>
                      <tr>
                        <th>Epost</th>
                        <td>{data.data.kontakt_email}</td>
                      </tr>
                      <tr>
                        <th>Telefon</th>
                        <td>{data.data.kontakt_phone}</td>
                      </tr>
                    </MDBTableBody>
                  </MDBTable>
                </MDBCardBody>
              </MDBCard>
            </MDBRow>
          </MDBCol>
          <MDBCol className='pb-3'>
            <MDBRow className='g-3 pe-2'>
              <MDBCard border='primary' className='p-2'>
                <MDBCardBody>
                  <MDBBadge light style={mainStyles.rubriker}>Betalning</MDBBadge>

                  <MDBTable small>

                    <MDBTableBody>
                      <tr>
                        <th>SwedbankPay</th>
                        {data.data.betalning_pay == 1 && (
                          <td className='text-success'>{'Aktiverad'}</td>
                        )}
                        {data.data.betalning_pay == 0 && (
                          <td className='text-danger'>{'Ej Aktiverad'}</td>
                        )}
                      </tr>
                      <tr>
                        <th>Kontonummer</th>
                        <td>{data.data.betalning_kontonr}</td>
                      </tr>
                      <tr>
                        <th>Swish nummer</th>
                        <td>{data.data.betalning_swish_nr}</td>
                      </tr>
                      <tr>
                        <th>Sista betalningsdatum</th>
                        <td>{data.data.betalning_end_date}</td>
                      </tr>
                      <tr>
                        <th>Betalningsinformation</th>
                        <td><pre style={{ fontSize: 14.4 }}>{data.data.betalning_information}</pre></td>
                      </tr>
                    </MDBTableBody>
                  </MDBTable>
                </MDBCardBody>
              </MDBCard>
            </MDBRow>
          </MDBCol>
        </MDBCol>

      </MDBRow>


    </>
  );
}

function Deltagare2(deltagar_arr) {

  const [data, setProps] = useState(deltagar_arr);

  const advancedData = {
    columns: [
      { label: 'Namn', field: 'full_name', sort: true },
      { label: 'Förening', field: 'forening_name', sort: true },
      { label: 'Klass', field: 'klass_name_display', sort: true },
    ],
    rows: data.deltagar_arr,
  };

  return (
    <>
      <MDBDatatable
        search
        noFoundMessage='Inga deltagare inlagda...'
        loaderClass='Laddar...'
        ofText='av'
        rowsText='Rader per sida:'
        data={advancedData}
      />
    </>
  );
}

function Deltagare(deltagar_arr) {

  const [data, setProps] = useState(deltagar_arr);

  return (
    <>

      <h2>Deltagare</h2>

      <MDBTable>

        <tbody>
          <tr>
            <th>Namn</th>
            <th>Förening</th>
            <th>Klass</th>
          </tr>
          {data.deltagar_arr.map(item => (<>
            <tr key={item.id}>

              <td>{item.last_name}, {item.first_name}</td>
              <td>{item.forening_name}</td>
              <td>{item.klass_name_display}</td>
            </tr>
          </>


          ))}

        </tbody>
      </MDBTable>
    </>
  );
}


function Dokument(doc_arr) {

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [file, setFile] = useState(null);
  const [displayname, setDisplayName] = useState(null);
  const [file_type, setFile_type] = useState("1");
  const [file_accept, setFile_accept] = useState(null);
  const [types, setLovTypes] = useState([]);
  const { id } = useParams();

  // Ref object to reference the input element
  const inputFile = useRef(null);

  // Function to reset the input element
  const handleReset = () => {
    console.log('reset', inputFile.current, inputFile);
    if (inputFile.current) {
      inputFile.current.value = "";
      inputFile.current.type = "text";
      inputFile.current.type = "file";
    }
    setFile(null);
    setDisplayName("");
  };

  useEffect(() => {



    fetch_get('app/event/documents/' + id)
      .then(data => {
        console.log('data_documents', data.data)
        setData(data.data);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setLoading(false);
      });


  }, [loading]);

  useEffect(() => {

    fetch_get('app/list/lov-document-types')
      .then(data => {
        setLovTypes(data.data);
        setFile_accept(data.data[0].file_types);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        //setLoading(false);
      });

  }, []);

  const ondeleteDocument = async (doc_id) => {

    console.log('ondeleteDocument', doc_id, id);
    const form_params = {
      "action": "event_delete_document",
      "doc_id": doc_id
    };



    const resJson = await fetch_post('app/event/do_submit/' + id, form_params);
    setLoading(true);

  };

  const onFileChange = async (event) => {
    //if (!displayname) {
      //let filename = event.target.files[0].name;
       setDisplayName(event.target.files[0].name);
    //}
    setFile(event.target.files[0]);

  };

  const onChangeDisplayName = (e) => {
    console.log('sss');
    setDisplayName(e.target.value);
    //setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };


  const onDocTypeChange = async (event) => {
    console.log(event);
    //setFile(event.target.files[0]);
    setFile_type(event.value);
    setFile_accept(event.file_types);
    handleReset();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    console.log('submit');
    const formData = new FormData();
    if (file) {
      // Update the formData object
      formData.append(
        "my_file",
        file,
        file.name
      );
      formData.append("action", "event_add_document");
      formData.append("db_document_type_id", file_type);
      formData.append("db_display_name", displayname);

      const resJson = await fetch_post_document('app/event/do_add_document/' + id, formData);

      handleReset();
      setLoading(true);
    }
  };

  return (
    <>
      <MDBRow>
        {/* <form onSubmit={handleSubmit}> */}
        <MDBCol size="3">
          <MDBSelect
            // preventFirstSelection
            label='Dokument typ'
            onChange={onDocTypeChange}
            data={types}
          />
        </MDBCol>
        <MDBCol size="6">

          <MDBInputGroup className='mb-3' onChange={onFileChange} col="3" label='Default file input example'>

            <input className="form-control form-control-undefined" type="file" id='customFile' accept={file_accept} ref={inputFile} />
            <MDBBtn outline type='submit' onClick={handleSubmit}>Ladda upp</MDBBtn>
          </MDBInputGroup>
        </MDBCol>
        <MDBCol size="3">

          <MDBInput label='Visningsnamn' onChange={onChangeDisplayName} name='displayname' type='text' value={displayname}/>
        </MDBCol>
        <MDBCol size="6">
          <p>Tillåtna format för dokument typen: {file_accept}</p>
        </MDBCol>
        {/* </form> */}
      </MDBRow>

      <MDBTable>

        <tbody>
          <tr>
            <th> </th>
            <th>Typ</th>
            <th>Namn</th>
            <th>Skapad</th>
          </tr>
          {data.map(item => (<>
            <tr key={item.id}>
              <td>
                <MDBPopconfirm
                  style={{ padding: '5px' }}
                  color="none"
                  modal
                  btnChildren={<MDBIcon far icon="trash-alt" color="danger" size='sm' />}

                  cancelBtnText='Avbryt'
                  onConfirm={() => ondeleteDocument(item.id)}
                  cancelBtnClasses='btn-secondary'
                  btnClassName='btn btn-link'
                  title={"Ta bort dokument"}
                >
                  <MDBPopconfirmMessage
                    icon={<MDBIcon className='me-2' far icon="trash-alt" color='danger' />}
                    size='lg'
                  >
                    Ta bort dokument: <br /><br />{item.file_name}?
                  </MDBPopconfirmMessage>
                </MDBPopconfirm>

              </td>

              <th>{item.document_type}</th>
              <td><a href={"https://dev.svsf-ta.se/api/app/document/get_document/" + item.key_id}>{item.display_name}</a></td>
              <td>{item.add_date}</td>

            </tr>
          </>


          ))}

        </tbody>
      </MDBTable>
    </>
  );
}

function Sportgrenar(arr) {

  const [data, setProps] = useState(arr);


  return (
    <>

      <MDBNavbar light bgColor='light'>
        <MDBContainer tag="form" fluid className='justify-content-start'>

          <NavLink
            className='btn btn-primary btn-sm me-2'
            as={Link}
            to={'/event/' + data.key_id + '/gren-klass'}
          >
            <MDBIcon fas icon="edit" className='me-2' />Ändra
          </NavLink>
        </MDBContainer>
      </MDBNavbar>

      <MDBRow className='g-3 py-3'>
        <MDBCol className='col-md-6 col-sm-12'>
          <MDBRow className='g-3 pe-2'>
            <MDBCard border='primary' className='p-2'>
              <MDBCardBody>
                <MDBBadge light style={mainStyles.rubriker}>GREN / DICIPLIN / KLASSER</MDBBadge>
                <MDBTable>


                  {data.arr.map(item => (
                    <>
                      <tbody>
                        <tr>
                          <th>Gren</th>
                          <th>Klasser/Startavgift</th>
                        </tr>
                        <tr key={item.sportgren_id}>

                          <td>{item.sportgren}</td>
                          <td>
                            <MDBTable>
                              {item.klasser.map(kl => (
                                <>
                                  <tr>
                                    <td className='text-nowrap'>{kl.klass_name}</td>
                                    <td className='text-nowrap'>{kl.startavgift} kr</td>
                                  </tr>
                                </>
                              ))}
                            </MDBTable>
                          </td>
                        </tr>
                      </tbody>
                    </>


                  ))}


                </MDBTable>

              </MDBCardBody>
            </MDBCard>
          </MDBRow>
        </MDBCol>

      </MDBRow>

    </>
  );
}
