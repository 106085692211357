import React from "react";
import {
	MDBBtn,
	MDBCard,
	MDBCardBody,
	MDBContainer,
	MDBRow,
	MDBCol,
	MDBCardTitle,
	MDBCardText,
	MDBListGroup,
	MDBListGroupItem
} from "mdb-react-ui-kit";
import { ListDeltagare, Test } from "../modules/Events1";
import packageJson from '../../../package.json';

export const loader = async ({ params }) => {
	// const res = await fetch('https://dev.svsf-ta.se/api/app/event/all/53a6c57f-377e-11ee-9385-00505692ffcc');
	// const resJson = await res.json();
	const resJson = localStorage.getItem("user");
	console.log(packageJson.version);
	return resJson;
};

const HomePage = () => (
	<>
		<div
			className="bg-image"
			style={{
				backgroundImage: "url('https://mdbcdn.b-cdn.net/img/Photos/Others/images/76.webp')",
				height: "100vh",
			}}
		>
			<MDBContainer className="py-4 p-3 bg-light bg-gradient rounded-5">
				<MDBRow>
					<MDBCol md='8'>
						<MDBCard>
							<MDBCardBody>
								<MDBCardTitle>Nyheter</MDBCardTitle>
								<MDBCardText>
									Nu kan vi börja testa!
								</MDBCardText>
							</MDBCardBody>
						</MDBCard>
					</MDBCol>
					<MDBCol md='4'>
						<MDBCard>
							<MDBCardBody>
								<MDBCardTitle>Changelog</MDBCardTitle>
								<MDBListGroup style={{ minWidth: '22rem' }} light className='mb-3'>
									<MDBListGroupItem>
										<p className='fw-normal'>Aktuell version: <span className='fw-bold'>v{packageJson.version}</span> <span className='fw-light'>(build: {packageJson.build_date})</span></p>
									</MDBListGroupItem>
									<MDBListGroupItem>
										<h5 className='fw-bold'>Första steget</h5>
										<p className='text-muted mb-2 fw-bold'>2024-01-11</p>
										<p className='text-muted mb-0'>
											Ibland kan det vara bra att ha en changelog.
										</p>
									</MDBListGroupItem>
								</MDBListGroup>
								<MDBBtn outline rounded className='w-100'>
									Visa alla
								</MDBBtn>
							</MDBCardBody>
						</MDBCard>
					</MDBCol>
				</MDBRow>




			</MDBContainer>
		</div>

	</>
);

export default HomePage;
