import React, { useState, useRef } from 'react';
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
	MDBCardTitle,
	MDBCardText
} from 'mdb-react-ui-kit';
import {
  Link
} from "react-router-dom";




export const loader = async ({ params }) => {
  const res = await fetch('https://dev.svsf-ta.se/api/app/event/all/53a6c57f-377e-11ee-9385-00505692ffcc');
  const resJson = await res.json();

  console.log('SettingHome Loader');

  return resJson;
};


export default function SettingHome() {
  //const loadObject = useLoaderData();
  //const [data, setData] = useState(loadObject);  

  console.log('SettingHome Function');

  const [basicOpen, setBasicOpen] = useState(true);
  const [sidenavOpen, setSidenavOpen] = useState(false);
  const [basicCollapse1, setBasicCollapse1] = useState(true);
  const [basicCollapse2, setBasicCollapse2] = useState(true);
  const contentRef = useRef(null);


  // *****************************


  return (
    <>
      <header>
        {/* <Sidenav basicOpen={basicOpen} setBasicOpen={setBasicOpen} /> */}
      </header>

      <MDBContainer className="py-4 bg-light bg-gradient rounded-5">   

        <h1>Systeminställningar</h1>
        <MDBRow className='pb-4'>
					<MDBCol md='4'>
						<MDBCard className='hover-shadow rounded-4 rounded bg-primary border border-primary h-100'>
							<Link style={{ color: '#000' }} to="/event-settings">
								<MDBRow className='g-0'>
										<MDBCardBody className='text-white'>
											<MDBCardTitle>Tävlingar</MDBCardTitle>
											<MDBCardText>
												Inställningar för tävlingar, t.ex Grenar, Klasser mm
											</MDBCardText>
										</MDBCardBody>
								</MDBRow>
							</Link>
						</MDBCard>
					</MDBCol>
					<MDBCol md='4'>
						<MDBCard className='hover-shadow rounded-4 rounded bg-primary border border-primary h-100'>
							<Link style={{ color: '#000' }} to="/under-construction">
								<MDBRow className='g-0'>
										<MDBCardBody className='text-white'>
											<MDBCardTitle>Föreningar/Distrikt</MDBCardTitle>
											<MDBCardText>
											Inställningar för förening och distrikt
											</MDBCardText>
										</MDBCardBody>
								</MDBRow>
							</Link>
						</MDBCard>
					</MDBCol>
					<MDBCol md='4'>
						<MDBCard className='hover-shadow rounded-4 rounded bg-primary border border-primary h-100'>
							<Link style={{ color: '#000' }} to="/search-users">
								<MDBRow className='g-0'>
										<MDBCardBody className='text-white'>
											<MDBCardTitle>Medlemmar</MDBCardTitle>
											<MDBCardText>
											Inställningar medlemmar
											</MDBCardText>
										</MDBCardBody>
								</MDBRow>
							</Link>
						</MDBCard>
					</MDBCol>
				</MDBRow>
				<MDBRow className='pb-4'>
					<MDBCol md='4'>
						<MDBCard className='hover-shadow rounded-4 rounded bg-primary border border-primary h-100'>
							<Link style={{ color: '#000' }} to="/under-construction">
								<MDBRow className='g-0'>
										<MDBCardBody className='text-white'>
											<MDBCardTitle>Ranking</MDBCardTitle>
											<MDBCardText>
											Inställningar ranking
											</MDBCardText>
										</MDBCardBody>
								</MDBRow>
							</Link>
						</MDBCard>
					</MDBCol>  
					<MDBCol md='4'>
						<MDBCard className='hover-shadow rounded-4 rounded bg-primary border border-primary h-100'>
							<Link style={{ color: '#000' }} to="/under-construction">
								<MDBRow className='g-0'>
										<MDBCardBody className='text-white'>
											<MDBCardTitle>Övrigt</MDBCardTitle>
											<MDBCardText>
											Övriga inställningar. Licenser mm
											</MDBCardText>
										</MDBCardBody>
								</MDBRow>
							</Link>
						</MDBCard>
					</MDBCol>      
        </MDBRow>








      </MDBContainer>

    </>
  );
}
