import React, { useState, useRef, useEffect } from 'react';
import {
  MDBSideNav,
  MDBSideNavMenu,
  MDBSideNavItem,
  MDBSideNavLink,
  MDBSideNavCollapse,
  MDBIcon,
  MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCheckbox,
  MDBCol,
  MDBDatepicker,
  MDBSelect,
  MDBInputGroup,
  MDBRadio,
  MDBInput,
  MDBValidationItem,
  MDBValidation,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBCardHeader,
  MDBAccordion,
  MDBAccordionItem,
  MDBCardSubTitle,
  MDBDatatable,
  MDBBadge,
  MDBModal,
  MDBModalHeader,
  MDBModalDialog,
  MDBModalContent,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBTextArea,
  MDBSwitch,
  MDBPopconfirm,
  MDBPopconfirmMessage,
  MDBNavbar,
  MDBTooltip,
  MDBDateTimepicker,
  MDBTimepicker,
  MDBTable
} from 'mdb-react-ui-kit';
import { useParams, useNavigate, NavLink, Link } from "react-router-dom";
import { fetch_get, fetch_post, fetch_delete } from "../../../services/common";


const customWeekdaysNarrow = ['S', 'M', 'T', 'O', 'T', 'F', 'L'];
const customMonthsFull = ['Januari', 'Februari', 'Mars', 'April', 'Maj', 'Juni', 'Juli', 'Augusti', 'September', 'Oktober', 'November', 'December',];
const customWeekdaysShort = ['Sön', 'Mån', 'Tis', 'Ons', 'Tor', 'Fre', 'Lör'];
const customMonthsShort = ['Jan', 'Feb', 'Mar', 'Apr', 'Maj', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'];


export default function EventEditStartlista({ params }) {
  // https://dev.svsf-ta.se/api/app/event/rounds/53a6c57f-377e-11ee-9385-00505692ffcc

  const { id } = useParams();
  const [data, setData] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [loading, setLoading] = useState(true);

  const [basicModal, setBasicModal] = useState(false);
  const toggleOpen = () => setBasicModal(!basicModal);

  const [basicModalSkjutlag, setBasicModalSkjutlag] = useState(false);
  const toggleOpenSkjutlag = () => setBasicModalSkjutlag(!basicModalSkjutlag);

  const [formValue, setFormValue] = useState({});
  const [formValueSkjuttid, setFormValueSkjuttid] = useState({});

  useEffect(() => {

    fetch_get('app/event/rounds/' + id)
      .then(data => {
        setData(data.data.rounds);
        setStartDate(data.data.start_date);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setLoading(false);
      });
  }, [loading]);

  const onChange = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };

  const mainStyles = {
    rubriker: {
      height: 20,
      position: 'absolute',
      top: '-10px',

    },
  };


  const onSubmitEvent = async (e) => {
    e.preventDefault();

    if (e.target.checkValidity()) {
      setBasicModal(false);
      console.log('save', formValue);
      const resJson = await fetch_post('app/event/do_submit/' + formValue.key_id, formValue);
      setLoading(true);
    } else {
      console.log('form not valid');
    }

  };

  const onSubmitSkjutlag = async (e) => {
    e.preventDefault();

    if (e.target.checkValidity()) {
      setBasicModalSkjutlag(false);
      const resJson = await fetch_post('app/event/do_submit/' + formValueSkjuttid.key_id, formValueSkjuttid);
      setLoading(true);
    } else {
      console.log('form not valid');
    }

  };

  const ondeleteRound = async (e) => {

    console.log('delete', e);
    const delete_obj =
    {
      action: 'event_round_delete',
      key_id: id,
      round_id: e
    };

    const resJson = await fetch_post('app/event/do_submit/' + formValue.key_id, delete_obj);
    setLoading(true);

  };

  const onChangeStarttidDate = (date) => {

    setFormValueSkjuttid({ ...formValueSkjuttid, 'start_date': date });
    console.log('aa', formValueSkjuttid);
  };

  const onChangeStarttid_start_time = (e) => {
    setFormValueSkjuttid({ ...formValueSkjuttid, 'start_time': e });
    console.log('aa2', formValueSkjuttid);
  };

  const onChangeStarttid_time_between_relays = (e) => {
    setFormValueSkjuttid({ ...formValueSkjuttid, 'time_between_relays': e });
    console.log('aa3', formValueSkjuttid);
  };


  const oneditStartTid = async (relay_id, starttid, da, ti, omgang_id) => {

    setFormValueSkjuttid(
      {
        action: 'event_set_starttime',
        key_id: id,
        relay_id: relay_id,
        omgang_id: omgang_id,
        start_date: da || startDate,
        start_time: ti || '08:00',
        time_between_relays: '00:00',
      });

    setBasicModalSkjutlag(true);

  };

  const onnew = async () => {

    console.log('new');
    setFormValue(
      {
        action: 'event_create_round',
        key_id: id,
        db_name: null,
        db_omgang: 1,
        no_of_relays: null,
        db_tavlor_per_skjutlag: null,
        db_tavel_nr_start: 1,
      });

    setBasicModal(true);

  };

  return (
    <>

      <MDBContainer className="py-4">

        <MDBNavbar light bgColor='light'>
          <MDBContainer tag="form" fluid className='justify-content-start'>
            <NavLink
              className='btn btn-primary btn-sm me-2'
              onClick={onnew}
            >
              <MDBIcon fas icon="plus" className='me-2' />Skapa tävlingsomgång
            </NavLink>


          </MDBContainer>
        </MDBNavbar>
        <MDBRow className='g-3 py-3'>
          <MDBCol className='col-md-8 col-sm-12'>
            <MDBRow className='g-3'>
              <MDBCard border='primary' className='p-2'>

                <MDBCardBody>
                  <MDBBadge light style={mainStyles.rubriker}>Tävlingsomgångar</MDBBadge>

                  <MDBTable>


                    {data.map((item, i) => (
                      <>
                        <tbody>
                          <tr key={i}>
                            <td>
                              <MDBTooltip placement='auto' tag='span' disableMouseDown={false} title={"Ta bort omgången"}>


                                <MDBPopconfirm
                                  outline
                                  size='sm'
                                  floating
                                  //className='call-btn me-1'
                                  color="danger"
                                  modal
                                  btnChildren={<MDBIcon far icon="trash-alt" color="danger" />}
                                  cancelBtnText='Avbryt'
                                  onConfirm={() => ondeleteRound(item.id)}
                                  cancelBtnClasses='btn-secondary'
                                  title={"Ta bort omgången"}
                                >
                                  <MDBPopconfirmMessage
                                    icon={<MDBIcon className='me-2' far icon="trash-alt" color='danger' />}

                                  >
                                    Ta bort omgång: {item.name}?
                                  </MDBPopconfirmMessage>
                                </MDBPopconfirm>

                                {/*                                 <MDBIcon
                                  onClick={() => console.log(item.id)}
                                  style={{ cursor: 'pointer' }}
                                  far
                                  icon="trash-alt"
                                  color='danger'
                                /> */}
                              </MDBTooltip>



                            </td>

                            <td>{item.omgang}</td>
                            <td>Omgång: <span className="fw-bold fs-6">{item.name}</span></td>
                            <td colSpan={2}>Tavlor/skjutlag: {item.tavlor_per_skjutlag}</td>
                            <td colSpan={2}>Tavelnr start: {item.tavel_nr_start}</td>

                          </tr>

                          {item.skjutlag.map((s_item, s) => (
                            <>
                              {s == 0 && (
                                <tr>
                                  <th></th>
                                  <th></th>
                                  <th></th>
                                  <th colSpan={2}>Skjutlag nr</th>
                                  <th>Starttider</th>
                                  <th> </th>

                                </tr>
                              )}
                              <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td className='text-nowrap'>{s_item.skjutlag}</td>
                                <td className='text-nowrap'>{s_item.da || 'yyyy:mm:dd'}</td>
                                <td className='text-nowrap'>{s_item.ti || 'hh:mi'}</td>

                                <td className='text-nowrap'>




                                  <MDBTooltip placement='auto' tag='span' title={"Sätt starttid för skjutlag: " + s_item.skjutlag}>

                                    <MDBBtn
                                      outline size='sm'
                                      floating
                                      className='call-btn me-1'
                                      onClick={() => oneditStartTid(s_item.id, s_item.start_tid, s_item.da, s_item.ti, s_item.omgang_id)}
                                    >
                                      <MDBIcon far icon="edit" />
                                    </MDBBtn>
                                    {/* 
                                    <MDBIcon
                                      onClick={() => oneditStartTid(s_item.id, s_item.start_tid, s_item.da, s_item.ti, s_item.omgang_id)}
                                      style={{ cursor: 'pointer' }}
                                      far
                                      icon="edit"
                                      color='primary'
                                    /> */}
                                  </MDBTooltip>

                                </td>
                              </tr>
                            </>
                          ))}


                        </tbody>
                      </>


                    ))}


                  </MDBTable>


                </MDBCardBody>
              </MDBCard>
            </MDBRow>
          </MDBCol>
        </MDBRow>




        <MDBModal open={basicModal} staticBackdrop setopen={setBasicModal} tabIndex='-1'>
          <MDBModalDialog>
            <MDBModalContent>
              <MDBValidation onSubmit={onSubmitEvent}>
                <MDBModalHeader>
                  <MDBModalTitle>Skapa tävlingsomgång

                  </MDBModalTitle>
                  <MDBBtn className='btn-close' type='reset' color='none' onClick={toggleOpen}></MDBBtn>
                </MDBModalHeader>
                <MDBModalBody>

                  <MDBRow>
                    <MDBValidationItem className='py-2 col-8' invalid feedback='Ange omgångens namn.'>
                      {basicModal && (
                        <MDBInput
                          name='db_name'
                          id='form_db_name'
                          label={<>Omgångsnamn</>}
                          type='text'
                          required
                          value={formValue.db_name}
                          onChange={onChange}
                        >

                          <MDBBadge pill className='position-absolute top-0 start-100  translate-middle bg-info' title="Hi! I'm a tooltip555555555!">
                            <MDBTooltip tag='a' title="Tester av tooltip!">
                              i
                            </MDBTooltip>
                          </MDBBadge>

                        </MDBInput>

                      )}
                    </MDBValidationItem>
                    <MDBValidationItem className='py-2 col-4' invalid feedback='Ange omgångsnr.'>
                      {basicModal && (
                        <MDBInput
                          name='db_omgang'
                          id='form_db_omgang'
                          label={<>Omgångsnr</>}
                          type='number'
                          required
                          value={formValue.db_omgang}
                          onChange={onChange}
                        >

                          <MDBBadge pill className='position-absolute top-0 start-100  translate-middle bg-info'>
                            <MDBTooltip tag='a' title="Tester av tooltip!">
                              i
                            </MDBTooltip>
                          </MDBBadge>

                        </MDBInput>
                      )}
                    </MDBValidationItem>
                    <MDBValidationItem className='py-2 col-6' invalid feedback='Ange antal skjutlag.'>
                      {basicModal && (
                        <MDBInput
                          name='no_of_relays'
                          id='form_no_of_relays'
                          label={<>Antal skjutlag</>}
                          type='number'
                          required
                          value={formValue.no_of_relays}
                          onChange={onChange}
                        >
                          <MDBIcon color='info' className='position-absolute top-0 start-100  translate-middle' fas icon="info-circle" />

                        </MDBInput>
                      )}
                    </MDBValidationItem>
                    <MDBValidationItem className='py-2 col-6' invalid feedback='Ange antal tavlor/skjutlag.'>
                      {basicModal && (
                        <MDBInput
                          name='db_tavlor_per_skjutlag'
                          id='form_db_tavlor_per_skjutlag'
                          label={<>Antal tavlor/skjutlag</>}
                          type='number'
                          required
                          value={formValue.db_tavlor_per_skjutlag}
                          onChange={onChange}
                        />
                      )}
                    </MDBValidationItem>
                    <MDBValidationItem className='py-2 col-6' invalid feedback='Ange antal tavlor/skjutlag.'>
                      {basicModal && (
                        <MDBInput
                          name='db_tavel_nr_start'
                          id='form_db_tavel_nr_start'
                          label={<>Tavelnummer start</>}
                          type='number'
                          required
                          value={formValue.db_tavel_nr_start}
                          onChange={onChange}
                        />
                      )}
                    </MDBValidationItem>


                  </MDBRow>


                </MDBModalBody>

                <MDBModalFooter>
                  <MDBBtn color='secondary' type='reset' onClick={toggleOpen}>
                    Avbryt
                  </MDBBtn>
                  <MDBBtn type='submit'>Skapa</MDBBtn>
                </MDBModalFooter>
              </MDBValidation>
            </MDBModalContent>
          </MDBModalDialog>
        </MDBModal>


        <MDBModal open={basicModalSkjutlag} staticBackdrop setopen={setBasicModalSkjutlag} tabIndex='-1'>
          <MDBModalDialog>
            <MDBModalContent>
              <MDBValidation onSubmit={onSubmitSkjutlag}>
                <MDBModalHeader>
                  <MDBModalTitle>Sätt starttid

                  </MDBModalTitle>
                  <MDBBtn className='btn-close' type='reset' color='none' onClick={toggleOpenSkjutlag}></MDBBtn>
                </MDBModalHeader>
                <MDBModalBody>

                  <MDBRow>

                    {basicModalSkjutlag && (
                      <>
                        <MDBValidationItem className='py-2 col-8' invalid feedback='Ange en starttid.'>
                          <MDBDatepicker
                            name='start_date'
                            disablePast
                            required
                            inputLabel={<>Start datum <strong className='text-muted'>*</strong></>}
                            format='yyyy-mm-dd'
                            startDay={1}
                            onChange={onChangeStarttidDate}
                            defaultValue={formValueSkjuttid.start_date}
                            value={formValueSkjuttid.start_date}
                            weekdaysNarrow={customWeekdaysNarrow}
                            monthsFull={customMonthsFull}
                            weekdaysShort={customWeekdaysShort}
                            monthsShort={customMonthsShort}
                            title='Välj datum'
                            cancelBtnText='Avbryt'
                            clearBtnText='Reset'
                            inline
                          />
                        </MDBValidationItem>
                        <MDBValidationItem className='py-2 col-4' invalid feedback='Ange starttid.'>
                          {basicModalSkjutlag && (
                            <MDBTimepicker
                              name='start_time'
                              inputLabel='Starttid'
                              onChange={onChangeStarttid_start_time}
                              inline
                              required
                              format="24h"
                              defaultValue={formValueSkjuttid.start_time}
                            />
                          )}
                        </MDBValidationItem>


                      </>
                    )}

                    <MDBRow>
                    </MDBRow>
                    <MDBValidationItem className='py-2 col-8' invalid feedback='Ange tid mellan skjutlagen.'>
                      {console.log('ll', formValueSkjuttid.time_between_relays)}
                      {basicModalSkjutlag && (
                        <MDBTimepicker
                          name='time_between_relays'
                          inputLabel='Tid mellan skjutlagen'
                          onChange={onChangeStarttid_time_between_relays}
                          inline
                          required
                          format="24h"
                          defaultValue={formValueSkjuttid.time_between_relays}
                        />
                      )}
                    </MDBValidationItem>
                  </MDBRow>


                </MDBModalBody>

                <MDBModalFooter>
                  <MDBBtn color='secondary' type='reset' onClick={toggleOpenSkjutlag}>
                    Avbryt
                  </MDBBtn>
                  <MDBBtn type='submit'>Spara</MDBBtn>
                </MDBModalFooter>
              </MDBValidation>
            </MDBModalContent>
          </MDBModalDialog>
        </MDBModal>


      </MDBContainer >

    </>
  );
}

