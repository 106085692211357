import React, { useState, useRef, useEffect } from 'react';
import { MDBBtn, MDBContainer, MDBRow } from "mdb-react-ui-kit";
import { Link, useRouteError } from "react-router-dom";

function NotFound404() {
    /* const [data, setData] = useState("");
    let error = useRouteError();
    useEffect(() => {    
    
    setData(error.statusText);
    console.log('error', error);
    console.log('error.data.sorry', error.data);
    }, [error]); */
    return (
  <MDBContainer>
    <MDBRow>
      <div className="col-12">
        <section className="my-5 text-center">
          <h1 className="display-1">404</h1>

          <h4 className="mb-4">Sidan hittades inte {/* ({data}) */}</h4>

          <p className="mb-0">
            Sidan du letar efter saknas eller så har något annat fel uppstått.
          </p>
          <p className="mb-4">
            Gå tillbaka eller klicka på knappen för att gå till startsidan.
          </p>
          <Link to="/">
            <MDBBtn>Tillbaka till startsidan</MDBBtn>
          </Link>
        </section>
      </div>
    </MDBRow>
  </MDBContainer>
);
  }
export default NotFound404;
