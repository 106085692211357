import {    
  MDBTable, 
  MDBTableHead, 
  MDBTableBody
 } from 'mdb-react-ui-kit';

export default function UserChilds(user){

	const childs = user.childs
  
  if(childs.length > 0){
    const childsList = childs.map((child,index) => {
      return <tr key={index}>
							<td><p className='mb-1'>{child.first_name + " " + child.last_name}</p></td>
            </tr>
    });
    return <div>
          <MDBTable align='middle'>
            <MDBTableHead>
              <tr>
                <th scope='col'>Namn</th>
              </tr>
            </MDBTableHead>
            <MDBTableBody>{childsList}</MDBTableBody>
          </MDBTable>
          </div>
          ;
  }

  return <p align='middle'>Användaren har inga barn kopplad till sig.</p>;
  
}