import { useLoaderData,useNavigate } from "react-router-dom";
import React, { useState, useRef } from 'react';
import { fetch_json, fetch_get } from "../../../services/common";
import {    
  MDBRow, 
  MDBCol, 
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBContainer,
  MDBIcon, 
  MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsContent,
  MDBTabsPane
 } from 'mdb-react-ui-kit';
import jwt_decode from "jwt-decode";
import LicenseTable from "./UserLicense";
import UserEvents from "./UserEvents";
import UserChilds from "./UserChilds";
import UserRoles from "./UserRoles";
import SideMenu from "../../modules/SideMenu";

export async function loader({ params }) {
  if(Object.keys(params).length === 0){
    const data = localStorage.getItem("token");
    var decoded = "";
    if (data) {
      decoded = jwt_decode(data);
    }

		const obj = {
			user: await fetch_get("app/user/all/" + decoded.kid),
			url: "/edit-user"
		}
      return obj;
    }
    else{
			console.log(params.id)
      const obj = {
				user: await fetch_get("app/user/all/" + params.id),
				url: "/edit-user"
			}
				return obj;
    }
  }

export default function UserDetails() {

	const loadObject = useLoaderData();
  const user = loadObject.user.data;
	const [profilePicutre, setProfilePicture] = useState('https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_960_720.png');
  const [basicActive, setBasicActive] = useState('resultTab');
	const inputRef = useRef(null);
	
	function hasChildren(){
		if(user.childs.length > 0){
			return true;
		}
	}

	function showRoles(){
		if(user.roles.length > 0){
			return true;
		}
	}

  const showUpload = () => {
    inputRef.current?.click();
  };

	const uploadChange = () => {
		console.log(inputRef.current.files[0])
    inputRef.current?.files && setProfilePicture(URL.createObjectURL(inputRef.current.files[0]));
  };

  const handleBasicClick = (value) => {
    if (value === basicActive) {
      return;
    }

    setBasicActive(value);
  };

	const menu = [
		{name: "Resultat", content: "Resultat"},
		{name: "Roller", content: UserRoles(user)},
		{name: "Tävlingar", content: UserEvents(user)},
		{name: "Licens", content: LicenseTable(user)},
		{name: "Barn", content: UserChilds(user)}];
  
  return (
    <>
      <MDBContainer className="py-1 p-3 border rounded-bottom bg-light">
        <MDBRow className='d-flex justify-content-center'>
					<MDBCard style={{ "--mdb-bg-opacity": "0.1" }} className="px-0 rounded-1 border-2 border-primary" shadow='0' border='primary'>
						<MDBRow className='g-0'>
							<MDBCol md='1'>
								<div className="bg-image justify-content-start rounded-0" style={{height: "135px",width: "100%",backgroundImage: "url('" + profilePicutre + "')"}}>										
									<input accept="image/*" ref={inputRef} onChange={uploadChange} className="d-none" type="file" />
									<button onClick={showUpload} className="btn btn-floating">
										<MDBIcon fas icon="file-upload" size='2x'/>
									</button>
								</div>
							</MDBCol>
							<MDBCol md='6'>
								<MDBCardBody >
									<MDBCardTitle>{user.user_name}</MDBCardTitle>
								</MDBCardBody>
							</MDBCol>
							<MDBCol md='5'>
								<MDBCardBody className="text-end">
									<MDBRow>
										<MDBCol><p className="text-muted mb-0">{user.first_name} {user.last_name}</p></MDBCol>
									</MDBRow>
									<MDBRow>
										<MDBCol><p className="text-muted mb-0">{user.email}</p></MDBCol>
									</MDBRow>
									<MDBRow>
										<MDBCol><p className="text-muted mb-0">{user.phone}</p></MDBCol>
									</MDBRow>
									<MDBRow>
										<MDBCol><p className="text-muted mb-0">{user.city}</p></MDBCol>
									</MDBRow>
								</MDBCardBody>
							</MDBCol>
						</MDBRow>
					</MDBCard>
				</MDBRow>
				<MDBRow className="pt-3 bg-white bg-gradient">
					<MDBCol md='12' className="p-0">
						<SideMenu heading="Min profil" menu={menu}/>
					</MDBCol>
				</MDBRow>        
      </MDBContainer>

    </>
  );
}