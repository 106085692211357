import React, { useState, useEffect } from 'react';
import {    
  MDBBtn,
	MDBModal,
	MDBModalBody,
	MDBModalContent,
	MDBModalTitle,
	MDBModalFooter,
	MDBModalHeader,
	MDBModalDialog,
	MDBDatatable,
	MDBDropdown,
	MDBDropdownMenu,
	MDBDropdownItem,
	MDBDropdownToggle,
	MDBIcon,
	MDBAlert,
	MDBPopconfirm,
	MDBPopconfirmMessage
 } from 'mdb-react-ui-kit';
import { fetch_get } from '../../../services/common';
import { EditLicense } from './service/EditLicense';

export default function EditLicenseModal(params){

	const user = params.sentUser[0];
	const sportLoader = params.formSelects.sport;
	const [loading, setLoading] = useState(false);
	const [refresh, setRefresh] = useState(false);
	const [licenseEditedToast, setLicenseEditedToast] = useState(false)
	const [licenseErrorToast, setLicenseErrorToast] = useState(false)
	const [emptyMsg, setEmptyMsg] = useState('Licens saknas');
	const sports = []
	const sportArray = (name, id, formName) => { return { text: name, value: id, name: formName}};
	sportLoader.data.map(sport => {
    const selectSport = sportArray(sport.text, Number(sport.value), "sport");
    sports.push(selectSport);
  },);

	const changeStatus = (id,status) => {
		EditLicense(user.key_id,id,status).then(obj => {
			if(obj.status == "OK"){
				setLicenseEditedToast((prev) => !prev);
				setRefresh(true)
			} else {
				setLicenseErrorToast((prev) => !prev)
			}
		});
		
	}

	const [asyncData, setAsyncData] = useState({
    columns: [
      { label: 'Licens', field: 'lic_name' },
			{ label: 'Gren/diciplin', field: 'gren_name' },
			{ label: 'Startdatum', field: 'start_date'},
			{ label: 'Slutdatum', field: 'end_date' },
			{ label: 'Status', field: 'status'},
			{ label: 'Ändra status', field: 'verktyg'}
    ],
    rows: [],
  });

	useEffect(() => {
		setLoading(true)
		fetch_get("app/user/licenser/" + user.key_id)
			.then((data) =>
				setTimeout(() => {
					if(data.data.length == 0){
						setEmptyMsg("Användaren saknar licens");
					}
					setAsyncData({
						columns: asyncData.columns,
						rows: data.data.map((licens) => ({
							...licens,
							verktyg: (
								<MDBDropdown dropleft > 
										<MDBDropdownToggle size='sm' style={{background: 'transparent', boxShadow: 'none'}}><MDBIcon style={{ color: '#0065A4'}} fas icon="ellipsis-h" /></MDBDropdownToggle>
										<MDBDropdownMenu className='align-items-center'>
										<MDBDropdownItem link childTag='button' onClick={() => changeStatus(licens.id,"ACTIVE")}>
												<h6>Aktivera</h6>
											</MDBDropdownItem>
											<MDBDropdownItem link childTag='button' onClick={() => changeStatus(licens.id,"PAUSED")}>
												<h6>Pausa</h6>
											</MDBDropdownItem>
											<MDBDropdownItem divider />
											<MDBDropdownItem link childTag='button' onClick={() => changeStatus(licens.id,"LOCKED")}>
												<h6>Spärra</h6>
											</MDBDropdownItem>
											{/* <MDBPopconfirm style={{width: '100%'}} modal color='secondary' btnChildren='Ta bort' onConfirm={() => changeStatus(licens.id,"DELETED")}>
												<MDBPopconfirmMessage>Ta bort licens {licens.lic_name}?</MDBPopconfirmMessage>
											</MDBPopconfirm> */}
											<MDBDropdownItem link childTag='button' onClick={() => changeStatus(licens.id,"DELETED")}>
												<h6>Ta bort</h6>
											</MDBDropdownItem>
										</MDBDropdownMenu>
									</MDBDropdown>
							)
						})),
					},
					setLoading(false),
					setRefresh(false));
				}, 3000)
			);
	}, [refresh,asyncData.columns]);

  return <>
					<MDBAlert
						color='primary'
						autohide
						position='top-right'
						delay={2000}
						appendToBody
						open={licenseEditedToast}
						onClose={() => setLicenseEditedToast(false)}
					>
						Licens har ändrats.
					</MDBAlert>
					<MDBAlert
						color='danger'
						autohide
						position='top-right'
						delay={2000}
						appendToBody
						open={licenseErrorToast}
						onClose={() => setLicenseErrorToast(false)}
					>
						Något gick fel vid ändring av licens.
					</MDBAlert>
					<MDBModal onClose={params.close} open={params.show} tabIndex='-1'>
						<MDBModalDialog size="lg">
							<MDBModalContent>
								<MDBModalHeader>
									<MDBModalTitle>{user.first_name + " " + user.last_name}</MDBModalTitle>
									<MDBBtn className='btn-close' color='none' onClick={params.close}></MDBBtn>
								</MDBModalHeader>
									<MDBModalBody>
										<MDBDatatable data={asyncData} isLoading={loading} loadingMessage={"Hämtar licenser..."} noFoundMessage={emptyMsg}/>
										</MDBModalBody>
									<MDBModalFooter>
									<MDBBtn color='secondary' onClick={params.close}>
										Stäng
									</MDBBtn>
								</MDBModalFooter>
							</MDBModalContent>
						</MDBModalDialog>
					</MDBModal>
					</>
  
}