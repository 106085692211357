import React,{useState} from 'react';
import { useNavigate } from "react-router-dom";
import { useLoaderData, Link } from "react-router-dom";
import { fetch_json } from "../../../../services/common";
import {
  MDBContainer,
  MDBBreadcrumb,
	MDBBreadcrumbItem,
  MDBRow,
  MDBCol,
	MDBListGroup,
	MDBListGroupItem,
	MDBDatatable,
	MDBBtn
} from 'mdb-react-ui-kit';
import { BorderHeadline } from '../../../modules/BorderHeadline';

export async function loader({ params }) {

	const obj = {
		associations: await fetch_json("data/distrikt_foreningar/?_filter=id=" + params.id),
		district: await fetch_json("app/distrikt/all/" + params.id, 'GET')
	};
    
  return obj;
}

export default function DistrictAssociations() {

  const loadObject = useLoaderData();
	const associations = loadObject.associations.data[0].foreningar;
	const district = loadObject.district.data;

	const navigate = useNavigate();
  const fixObject = (id, association_name, idrottonline_id,actions) => { 
    return { 
      id: id, 
      association_name: association_name, 
      idrottonline_id: idrottonline_id, 
      actions: actions}};
			
  const associationRows = associations.map(association => {

    Object.keys(association).map((key) =>{
      if(association[key] == null){
        association[key] = "";
      }
    })
    const fixedRow = fixObject(
      association.id,
      association.name,
      association.idrottonline_id,
      "");

    return fixedRow;
    
  },);

	const [associationData,setAssociationData] = useState({
    
    columns: [
      { label: "Föreningsnamn", field: "association_name"},
      { label: "Idrottonline-id", field: "idrottonline_id"},
			{ label: "Administrera", field: "actions",fixed: 'right', sort: false }
    ],
    rows: associationRows.map((row) => {
      return {
        ...row,
				actions: (
          <div>
            <MDBBtn onClick={() => {navigate("/team/user-admin/"+row.id)}} color='link' rounded size='sm'>
              Medlemmar
            </MDBBtn>
            <MDBBtn onClick={() => {navigate("/team/facilities/"+row.id)}} color='link' rounded size='sm'>
              Anläggningar
            </MDBBtn>
          </div>
        )

      }
    }),
  });

  return (
    <>
      <MDBContainer className="py-4">    
				<MDBBreadcrumb>
					<MDBBreadcrumbItem>
						<a href='/district'>{district.name}</a>
					</MDBBreadcrumbItem>
					<MDBBreadcrumbItem active>Föreningar</MDBBreadcrumbItem>
				</MDBBreadcrumb>
        <MDBRow> 
					<MDBCol className='col-md-9.5 square border border-primary rounded-6 p-4 mx-1'>						
						<MDBDatatable 
							multi
							search
							data={associationData}
							bordered
							sm
							striped
							hover
					/>
					</MDBCol>
					<MDBCol className='col-md-2 square border border-primary rounded-6 p-4 mx-1 position-relative'>
						<BorderHeadline headline="Administratörer"/>
							<MDBListGroup>
								{district.admin_list.map((admin,index)=> {
									return <MDBListGroupItem className='text-center' key={index}>{admin.name}</MDBListGroupItem>
								})}
							</MDBListGroup>
					</MDBCol>
        </MDBRow>
      </MDBContainer>
    </>
  );
}