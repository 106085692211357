import { useLoaderData } from "react-router-dom";
import React, { useState } from 'react';
import { fetch_json } from "../../services/common";
import {    
  MDBRow, 
  MDBInput,
  MDBInputGroup,
  MDBBtn,
  MDBCheckbox,
  MDBValidation,
  MDBValidationItem,
  MDBContainer
 } from 'mdb-react-ui-kit';
 import jwt_decode from "jwt-decode";

export async function loader({ params }) {

  const data = localStorage.getItem("token");
  var decoded = "";
  if (data) {
    decoded = jwt_decode(data);
  }
  if(Object.keys(params).length === 0){
    const obj = await fetch_json("data/users/?_top=1&_select=*&_filter=key_id=\"" + decoded.kid + "\"" , 'POST');
    return  obj;
  }
  else{
    const obj = await fetch_json("data/users/?_top=1&_select=*&_filter=id=" + params.id,'POST');
    return obj;
  }
}

export default function EditUser() {

  const user = useLoaderData().data[0];

  const [formValue, setFormValue] = useState({
    fname: user.first_name,
    lname: user.last_name,
  });

  const onChange = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };

  return (
    <>
      <MDBContainer className="py-4">
        <MDBRow className='d-flex justify-content-center'>
          <MDBValidation className='row g-3' isValidated>
            <MDBValidationItem className='col-md-6'>
              <MDBInput
                value={formValue.fname}
                name='fname'
                onChange={onChange}
                id='validationCustom01'
                required
                label='First name'
              />
            </MDBValidationItem>
            <MDBValidationItem className='col-md-6'>
              <MDBInput
                value={formValue.lname}
                name='lname'
                onChange={onChange}
                id='validationCustom02'
                required
                label='Last name'
              />
            </MDBValidationItem>
            <br/><hr/>
            <MDBValidationItem className='col-12 d-flex justify-content-center' feedback='You must agree before submitting.' invalid>
              <MDBCheckbox className='col-12 d-flex justify-content-center' label='Agree to terms and conditions' id='invalidCheck' required />
            </MDBValidationItem>
            <div className='col-12 d-flex justify-content-center'>
              <MDBBtn type='submit'>Submit form</MDBBtn>
            </div>
          </MDBValidation>
        </MDBRow>
      </MDBContainer>

    </>
  );
}