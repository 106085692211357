import React, { useState, useRef } from 'react';
import { MDBFilters } from 'mdb-react-filters';
import {
  MDBSideNav,
  MDBSideNavMenu,
  MDBSideNavItem,
  MDBSideNavLink,
  MDBSideNavCollapse,
  MDBIcon,
  MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCheckbox,
  MDBCol,
  MDBDatepicker,
  MDBSelect,
  MDBInputGroup,
  MDBDatatable
} from 'mdb-react-ui-kit';
import {
  Outlet,
  NavLink,
  Link,
  useLoaderData,
  Form,
  redirect,
  Navigate,
  useNavigate,
  //useSubmit,
} from "react-router-dom";
import Icon_Svsf from "../../svg/svg_icon_svsf";
import { fetch_get, fetch_post } from "../../../services/common";




export const loader = async ({ params }) => {
  const resJson = await fetch_get('app/list/forening-events');
  //const resJson = await res.json();

  return resJson;
};


export default function EventsHome() {
  const loadObject = useLoaderData();
  const [data, setData] = useState(loadObject);  

  const [basicOpen, setBasicOpen] = useState(true);
  const [sidenavOpen, setSidenavOpen] = useState(false);
  const [basicCollapse1, setBasicCollapse1] = useState(true);
  const [basicCollapse2, setBasicCollapse2] = useState(true);
  const contentRef = useRef(null);


  const navigate = useNavigate();

  const advancedData = {
    columns: [
      { label: 'Start', field: 'start_date', sort: true },
      { label: 'Namn', field: 'name', sort: true },
    ],
    rows: data.data,
  };
  

  return (
    <>
      <header>
        {/* <Sidenav basicOpen={basicOpen} setBasicOpen={setBasicOpen} /> */}
      </header>

<MDBContainer className="py-4">
    
      <h1>Våra tävlingar</h1>



        <MDBRow>
          <MDBDatatable
            bordered
            pagination={true}
            noFoundMessage='Inga tävlingar...'
            loaderClass='Laddar...'
            ofText='av'
            rowsText='Rader per sida:'
            data={advancedData}
            onRowClick={(row) => {
              //const rowElement = row as { name };
              //const { from, message } = rowElement;
              console.log(row.key_id);
              ///return Navigate("/event/" + row.key_id);
              navigate("/event/" + row.key_id);
              //setBasicModal(true);
              //setModalState({ from, message });
            }}
          />
        </MDBRow>






  </MDBContainer>

    </>
  );
}
