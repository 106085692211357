import React, { useMemo, useState, useEffect } from "react";
import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBBadge,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBInputGroup,
  MDBDatepicker,
  MDBSelect,
} from "mdb-react-ui-kit";
import { fetch_get, fetch_json } from "../../../services/common";

import {
  MaterialReactTable,
  useMaterialReactTable,
  MRT_Table,
  MRT_TableHeadCellFilterContainer,
  MRT_TableContainer,

} from 'material-react-table';

import { Checkbox, MenuItem, darken, Paper, Stack, } from '@mui/material';
import { FavoriteBorder, Favorite } from '@mui/icons-material';
// import { makeStyles } from "@mui/styles";
import { MRT_Localization_SV } from 'material-react-table/locales/sv';






export const loader = async ({ params }) => {
  //const res = await fetch_get('app/event/all/53a6c57f-377e-11ee-9385-00505692ffcc');

  return [];
};

const EventsPage = () => {

  const [event_data, setData] = useState([]);
  const [load_data, setLoadData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [filterValue, setFilterValue] = useState(null);
  const [defaultfilterValue, setDefaultFilterValue] = useState(null);
  const [filterModal, setFilterModal] = useState(false);
  const toggleOpen = () => setFilterModal(!filterModal);

  const [validationErrors, setValidationErrors] = useState({});
  //keep track of rows that have been edited
  const [editedUsers, setEditedUsers] = useState({});

  useEffect(() => {

    fetchData();

  }, [filterValue]);

  const fetchData = async () => {
    let url = "";
    if (filterValue) {
      url = 'app/list/events-union?_start_date=' + filterValue.start_date
        + '&_end_date=' + filterValue.end_date
        + '&_gren=' + filterValue.gren
        + '&_gren_txt=' + filterValue.gren_txt
        + '&_disciplin=' + filterValue.disciplin
        + '&_disciplin_txt=' + filterValue.disciplin_txt
        + '&_disciplin1=' + filterValue.disciplin1
        + '&_disciplin2=' + filterValue.disciplin2
        + '&_kategori=' + filterValue.kategori
        + '&_forening=' + filterValue.forening
        + '&_distrikt=' + filterValue.distrikt
        ;
    } else {
      url = 'app/list/events-union';
    }
    const obj = await fetch_json(url);
    setData(obj.data);
    setLoadData(obj.data);
    if (loading) {
      //setFilterValue(obj.default_filter);
      setDefaultFilterValue(obj.default_filter);
    }
    setLoading(false);

  };

  /*
    useEffect(() => {
      console.log('load_data', load_data);
      if (load_data && filterValue) {
        console.log('filterValue', filterValue, filterValue.forening, filterValue.forening > 0);
        let filteredData = load_data.filter((row) => {
          return (row.kategori_id === filterValue.kategori || filterValue.kategori == 0)
            //xxxxxxx && (row.arrangorer_id ? row.arrangorer_id.indexOf('|' + filterValue.forening + '|') : false || !filterValue.forening)
            && (row.arrangorer_id && filterValue.forening > 0 ? row.arrangorer_id.split(',').includes(filterValue.forening) : false || filterValue.forening == 0)
            && (row.d_sportgren_id && filterValue.disciplin > 0 ? row.d_sportgren_id.split('_').includes(filterValue.disciplin) : false || filterValue.disciplin == 0)
            && (row.d_sportgren_id && filterValue.disciplin1 > 0 ? row.d_sportgren_id.split('_').includes(filterValue.disciplin1) : false || filterValue.disciplin1 == 0)
            && (row.d_sportgren_id && filterValue.disciplin2 > 0 ? row.d_sportgren_id.split('_').includes(filterValue.disciplin2) : false || filterValue.disciplin2 == 0)
            && (row.distrikt_id && filterValue.distrikt > 0 ? row.distrikt_id.split(',').includes(filterValue.distrikt) : false || filterValue.distrikt == 0)
            && (row.d1_sequence && filterValue.gren > 0 ? filterValue.gren.includes(row.d1_sequence) : false || filterValue.gren == 0)
            && (row.start_date >= filterValue.start_date && row.start_date <= filterValue.end_date)
            && (Object.keys(row).some(key => row[key] ? row[key].toUpperCase().includes(filterValue.free_text.toUpperCase()) : false))
            ;
          //return row.age > 40 && user.occupation === 'programmer';
        });
        //console.log('filteredData', filteredData);
        setData(filteredData);
      }
  
    }, [filterValue]);  
  */
  const columns = useMemo(
    () => [
      {
        accessorKey: 'start_date',
        header: 'Start',
        size: 80,

      },
      {
        accessorKey: 'name',
        header: 'Namn',
        size: 100,
        minSize: 20,
        enableResizing: false,
        Cell: ({ cell, row }) => (
          <>

            <span >{cell.getValue()}</span>

          </>
        ),
      },
      {
        accessorKey: 'd2_name',
        header: 'Gren/Disciplin',
        filterVariant: 'select',
        size: 150,
        minSize: 20,
      },
      {
        accessorKey: 'arrangorer',
        header: 'Arrangörer',
        minSize: 0,
        size: 0,
        color: 'red',
        Cell: ({ cell, row }) => (
          <>

            &nbsp;&nbsp;&nbsp;{cell.getValue()}

          </>
        ),
      },
      {
        accessorKey: 'status_name',
        header: 'Status',
        size: 10,
        filterVariant: 'multi-select',
        Cell: ({ cell, row }) => (
          <>

            <MDBBadge pill light color={row.original.status_color}>{cell.getValue()}</MDBBadge>

          </>
        ),
      },

      {
        accessorKey: 'anmalan_start_date',
        header: 'Anmälan',
        size: 200,
        showColumnFilters: false,

        Cell: ({ cell, row }) => (
          <>

            {cell.getValue()} - {row.original.anmalan_end_date}

          </>
        ),
      },


      {
        accessorKey: 'arrangorer2',
        header: '',
        size: 30,
        filterVariant: 'select',
        Cell: ({ cell }) => (
          <>

            <Checkbox className="xxfloat-end" icon={<FavoriteBorder />} value="1" checkedIcon={<Favorite color="error" />} />

          </>
        ),
      },
    ],
    [],
  );




  const table = useMaterialReactTable({
    columns,
    data: event_data, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
    // enableColumnActions: false,
    //createDisplayMode: 'row', // ('modal', and 'custom' are also available)
    //editDisplayMode: 'table', // ('modal', 'row', 'table', and 'custom' are also available)   
    //enableCellActions: true,
    enablePagination: false,
    enableColumnFilters: true,
    enableColumnActions: false,
    enableSorting: true,
    localization: MRT_Localization_SV,
    enableFacetedValues: true,

    //enableColumnResizing: true,
    //columnResizeMode: 'onEnd', //instead of the default "onChange" mode
    layoutMode: 'grid', // `grow` only works in the grid* layout modes

    columnFilterDisplayMode: 'popover', //we will render our own filtering UI
    // muiFilterTextFieldProps: ({ column }) => ({
    //   label: `Filter by ${column.columnDef.header}`,
    // }),


    // muiFilterTextFieldProps: {
    //   sx: { m: '0.5rem 0', width: '100%' },
    //   variant: 'outlined',
    // },

    //enableEditing: true,
    //enableRowSelection: true,
    enableColumnPinning: true,
    initialState: {
      // showColumnFilters: true,
      showGlobalFilter: true,
      density: 'compact',
      columnPinning: {
        right: ['mrt-row-actions'],
      },
    },
    enableDensityToggle: false,
    enableRowActions: true,
    positionActionsColumn: 'last',
    renderRowActionMenuItems: ({ row }) => [
      <MenuItem key="edit" onClick={() => console.info('Edit:' + row.original.anmalan_end_date)}>
        Anmälan
      </MenuItem>,
      <MenuItem key="delete" onClick={() => console.info('Delete')}>
        Mer information
      </MenuItem>,
    ],
    // muiTableBodyRowProps: ({ row }) => ({
    //   //conditionally style selected rows
    //   sx: {
    //     backgroundColor: !row.original.key_id ? 'dark' : '',
    //   },
    // }),

    options: {
      rowStyle: (rowData) => {
        return {
          fontFamily: "Mulish-Regular",
          backgroundColor: row.original.key_id.length > 0 ? 'blue' : 'red',
        };
      }
    },

  });

  //console.log(MRT_Localization_SV);
  // console.log('table', table.getState());
  // console.log('table', table);
  // console.log('table', table.getLeafHeaders());

  const validateRequired = (value) => !!value.length;

  return (
    <MDBContainer className="py-4">
      <h1>Tävlingskalender</h1>
      <MDBRow className='text-center'>
        <MDBCol md='2'>
          <MDBBtn onClick={toggleOpen} className='m-1 float-start'>Filter</MDBBtn>
        </MDBCol>
      </MDBRow>
      <MDBRow>

        <MaterialReactTable table={table} />



        {/* <Paper>

          <Stack p="8px" gap="8px">

            {table.getLeafHeaders().map((header) => (

              <MRT_TableHeadCellFilterContainer
                key={header.id}
                header={header}
                table={table}
                in
              />

            ))}

          </Stack>

        </Paper> */}

        <FilterModal
          filterModal={filterModal}
          setFilterModal={setFilterModal}
          toggleOpen={toggleOpen}
          setFilterValue={setFilterValue}
          filterValue={filterValue ? filterValue : defaultfilterValue}
          defaultfilterValue={defaultfilterValue}
          table={table}
        />

        {/* Denna tar bort menyer  */}
        {/* <MRT_Table table={table} /> */}
      </MDBRow>
    </MDBContainer>
  )
};

export default EventsPage;


const FilterModal = (props) => {

  const [lov_foreningar, setLovForeningar] = useState([]);
  const [lov_grenar, setLovGrenar] = useState([]);
  const [lov_grenar_discipliner, setLovGrenarDiscipliner] = useState([]);
  const [lov_kategorier, setLovKategorier] = useState([]);
  const [lov_distrikt, setLovDistrikt] = useState([]);
  const [lov_disciplin, setLovDisciplin] = useState([]);
  const [lov_disciplin1, setLovDisciplin1] = useState([]);
  const [lov_disciplin2, setLovDisciplin2] = useState([]);
  const customWeekdaysNarrow = ['S', 'M', 'T', 'O', 'T', 'F', 'L'];
  const customMonthsFull = ['Januari', 'Februari', 'Mars', 'April', 'Maj', 'Juni', 'Juli', 'Augusti', 'September', 'Oktober', 'November', 'December',];
  const customWeekdaysShort = ['Sön', 'Mån', 'Tis', 'Ons', 'Tor', 'Fre', 'Lör'];
  const customMonthsShort = ['Jan', 'Feb', 'Mar', 'Apr', 'Maj', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'];


  useEffect(() => {

    fetch_get('app/list/lov-foreningar')
      .then(data => {
        data.data.unshift({ "value": "%", "text": "Alla" });
        setLovForeningar(data.data);
        //console.log('setLovForeningar', data.data);
        //setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        //setLoading(false);
      });


    fetch_get('app/list/lov-kategorier')
      .then(data => {
        data.data.unshift({ "value": "%", "text": "Alla" });
        setLovKategorier(data.data);
        //console.log('setLovKategorier', data.data);
        //setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        //setLoading(false);
      });

    fetch_get('app/list/lov-distrikt')
      .then(data => {
        data.data.unshift({ "value": "%", "text": "Alla" });
        setLovDistrikt(data.data);
        console.log('setLovDistrikt', data.data);
        //setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        //setLoading(false);
      });

    fetch_get('app/list/lov-grenar-discipliner')
      .then(data => {
        setLovGrenarDiscipliner(data.data);

        let filteredData = data.data.filter((row) => {
          return (row.type === '10')
            ;

        });

        console.log(filteredData);
        filteredData.unshift({ "value": "%", "text": "Alla" });
        setLovGrenar(filteredData);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        //setLoading(false);
      });

  }, []);

  const onChange = (e, type, name = '') => {
    //  if (load_data) {
    console.log('onChange', e, type, name);
    let tmp_filterValue = props.filterValue;
    if (type === 'text') {

      //setFilterValue({ ...filterValue, [e.target.name]: e.target.value });
      tmp_filterValue = { ...tmp_filterValue, [e.target.name]: e.target.value };
    } else if (type === 'date') {

      //setFilterValue({ ...filterValue, [name]: e });
      tmp_filterValue = { ...tmp_filterValue, [name]: e };
    } else if (type === 'select_multi') {
      let grenArr = e.map((item) => item.value);

      //setFilterValue({ ...filterValue, [name]: grenArr });
      tmp_filterValue = { ...tmp_filterValue, [name]: grenArr };
    } else {
      //  select
      //setFilterValue({ ...filterValue, [name]: e.value });
      tmp_filterValue = { ...tmp_filterValue, [name]: e.value };
    }



    // }

    if (name === 'gren') {
      console.log('gren', e.value, e.text);
      // setFilterValue({ ...filterValue, ['disciplin']: 0 });
      //tmp_filterValue = { ...tmp_filterValue, ['gren_txt']: e.text };
      tmp_filterValue = { ...tmp_filterValue, ['disciplin']: "%", ['gren_txt']: e.text };

      let filteredData = lov_grenar_discipliner.filter((row) => {
        return (row.parent_id === e.value)
          ;
      });


      console.log(filteredData);
      filteredData.unshift({ "value": "%", "text": "Alla" });
      setLovDisciplin(filteredData);
    }

    if (name === 'disciplin') {
      console.log('disciplin', e.value, e.text);
      // setFilterValue({ ...filterValue, ['disciplin']: 0 });
      tmp_filterValue = { ...tmp_filterValue, ['disciplin1']: "%", ['disciplin_txt']: e.text };

      let filteredData = lov_grenar_discipliner.filter((row) => {
        return (row.parent_id === e.value)
          ;
      });


      console.log(filteredData);
      filteredData.unshift({ "value": "%", "text": "Alla" });
      setLovDisciplin1(filteredData);
    }

    if (name === 'disciplin1') {
      console.log('disciplin1', e.value);
      // setFilterValue({ ...filterValue, ['disciplin']: 0 });
      tmp_filterValue = { ...tmp_filterValue, ['disciplin2']: "%" };

      let filteredData = lov_grenar_discipliner.filter((row) => {
        return (row.parent_id === e.value)
          ;
      });


      console.log(filteredData);
      filteredData.unshift({ "value": "%", "text": "Alla" });
      setLovDisciplin2(filteredData);
    }



    // if (!loading) {
    //   sessionStorage.setItem('tmp_event_list_filter', JSON.stringify(tmp_filterValue));
    // }

    props.setFilterValue(tmp_filterValue);
  };


  const resetFilter = () => {
    console.log('resert filter', props.defaultfilterValue);
    props.setFilterValue(props.defaultfilterValue);
  };

  return (
    <MDBModal
      animationDirection='left'
      open={props.filterModal}
      tabIndex='-1'
      setOpen={props.setFilterModal}
    >
      <MDBModalDialog
        position='top-left'
        side
        size='sm'
      // className='position-absolute top-0 start-0'
      >
        {/* {filterValue && ( */}
        {props.filterValue && (
          <MDBModalContent className='h-100'>
            <MDBModalHeader className=''>
              <MDBModalTitle>Filter</MDBModalTitle>
              <MDBBtn
                color='none'
                className='btn-close'
                onClick={props.toggleOpen}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              <MDBContainer className='w-100 p-1 justify-content-left'>
                <MDBRow className=''>
                  <MDBInputGroup className='' noBorder textAfter={<MDBIcon fas icon='search' />}>
                    <input
                      name='free_text'
                      className='form-control'
                      type='text'
                      placeholder='Text sök'
                      label='Text input'
                      value={props.filterValue.free_text}
                      onChange={(e) => onChange(e, 'text')}
                    />

                  </MDBInputGroup>
                </MDBRow >

                <MDBRow className='pt-3 px-2'>
                  <MDBDatepicker
                    name='start_date'
                    format='yyyy-mm-dd'
                    defaultValue='2024-01-01'
                    startDay={1}
                    weekdaysNarrow={customWeekdaysNarrow}
                    monthsFull={customMonthsFull}
                    weekdaysShort={customWeekdaysShort}
                    monthsShort={customMonthsShort}
                    inline
                    inputLabel='Från datum'
                    max={new Date(props.filterValue.end_date)}
                    value={props.filterValue.start_date}
                    onChange={(e) => onChange(e, 'date', 'start_date')}
                  />
                </MDBRow >

                <MDBRow className='pt-3 px-2'>
                  <MDBDatepicker
                    name='end_date'
                    format='yyyy-mm-dd'
                    defaultValue='2024-12-31'
                    inline inputLabel='Till datum'
                    startDay={1}
                    weekdaysNarrow={customWeekdaysNarrow}
                    monthsFull={customMonthsFull}
                    weekdaysShort={customWeekdaysShort}
                    monthsShort={customMonthsShort}
                    min={new Date(props.filterValue.start_date)}
                    value={props.filterValue.end_date}
                    onChange={(e) => onChange(e, 'date', 'end_date')}
                  />
                </MDBRow >

                <MDBRow className='pt-2'>
                  <MDBSelect
                    name='gren'
                    label='Gren'
                    //noResultLabel="test"
                    // placeholder='Välj förening'
                    data={lov_grenar}
                    preventFirstSelected
                    value={props.filterValue.gren}
                    onValueChange={(e) => onChange(e, 'select', 'gren')}

                  //multiple
                  //selectAll={false}

                  />
                </MDBRow>

                {!(props.filterValue.gren == '%') && lov_disciplin.length > 1 && (
                  <MDBRow className='pt-2'>
                    <MDBSelect
                      name='disciplin'
                      label='Disciplin'
                      data={lov_disciplin}
                      preventFirstSelected
                      value={props.filterValue.disciplin}
                      onValueChange={(e) => onChange(e, 'select', 'disciplin')}
                    />
                  </MDBRow>
                )}

                {!(props.filterValue.gren == '%') && !(props.filterValue.disciplin == '%') && lov_disciplin1.length > 1 && (
                  <MDBRow className='pt-2'>
                    <MDBSelect
                      name='disciplin1'
                      label='Under Disciplin 1'
                      data={lov_disciplin1}
                      preventFirstSelected
                      value={props.filterValue.disciplin1}
                      onValueChange={(e) => onChange(e, 'select', 'disciplin1')}
                    />
                  </MDBRow>
                )}

                {!(props.filterValue.gren == '%') && !(props.filterValue.disciplin1 == '%') && lov_disciplin2.length > 1 && (
                  <MDBRow className='pt-2'>
                    <MDBSelect
                      name='disciplin2'
                      label='Under Disciplin 2'
                      data={lov_disciplin2}
                      preventFirstSelected
                      value={props.filterValue.disciplin2}
                      onValueChange={(e) => onChange(e, 'select', 'disciplin2')}
                    />
                  </MDBRow>
                )}

                <MDBRow className='pt-2'>
                  <MDBSelect
                    name='kategori'
                    label='Kategori'
                    //noResultLabel="test"
                    // placeholder='Välj förening'
                    data={lov_kategorier}
                    //preventFirstSelected
                    value={props.filterValue.kategori}
                    onValueChange={(e) => onChange(e, 'select', 'kategori')}
                  // search
                  //multiple
                  />
                </MDBRow>
                <MDBRow className='pt-2'>
                  <MDBSelect
                    name='forening'
                    label='Förening'
                    //noResultLabel="test"
                    // placeholder='Välj förening'
                    data={lov_foreningar}
                    //preventFirstSelected
                    value={props.filterValue.forening}
                    onValueChange={(e) => onChange(e, 'select', 'forening')}
                    search
                  //multiple
                  />
                </MDBRow>
                <MDBRow className='pt-2'>
                  <MDBSelect
                    name='distrikt'
                    label='Distrikt'
                    //noResultLabel="test"
                    // placeholder='Välj förening'
                    data={lov_distrikt}
                    value={props.filterValue.distrikt}
                    onValueChange={(e) => onChange(e, 'select', 'distrikt')}
                    //preventFirstSelected
                    search
                  //multiple
                  />
                </MDBRow>

                <MDBRow className='px-5 pt-4'>
                  <MDBBtn
                    color='danger'
                    //className='btn-close'
                    onClick={resetFilter}
                  >
                    Nollställ filter
                  </MDBBtn>
                </MDBRow>


                {/* <Paper>

                  <Stack p="8px" gap="8px">

                    {props.table.getLeafHeaders().map((header) => (
                      <>
                      {console.log('header', header.id, header)}
                      <MRT_TableHeadCellFilterContainer
                        key={header.id}
                        header={header}
                        table={props.table}
                        in
                      />
</>
                    ))}

                  </Stack>

                </Paper> */}

              </MDBContainer>

            </MDBModalBody>
          </MDBModalContent>
        )}
      </MDBModalDialog>
    </MDBModal>
  )
}