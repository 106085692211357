import React,{useState} from 'react';
import { useLoaderData, Link } from "react-router-dom";
import { fetch_json } from "../../../services/common";
import {
  MDBContainer,
  MDBCard,
  MDBBreadcrumb,
	MDBBreadcrumbItem,
  MDBCardBody,
  MDBCardText,
  MDBCardTitle,
  MDBRow,
  MDBCol,
  MDBCardImage,
	MDBSelect
} from 'mdb-react-ui-kit';
import jwt_decode from "jwt-decode";

export async function loader({ params }) {
  
  const data = localStorage.getItem("token");
  var decoded = "";
  if (data) {
    decoded = jwt_decode(data);
  }
    const obj = await fetch_json("app/user/all/" + decoded.kid);
    return obj;
}


export default function TeamHome() {

  const loadObject = useLoaderData();
	let forening_data = [];
	loadObject.data.foreningar.map(forening => {
		forening_data.push({text: forening.name, value: forening.forening_id})
	})

	const [selectedAssociaion, setSelectedAssociation] = useState(loadObject.data.foreningar[0].forening_id);

	const handleChange = (value) => {
    setSelectedAssociation(value.value);
  };

  return (
    <>
      <MDBContainer className="py-4 p-3 bg-light bg-gradient rounded-5">    
				<h1>Föreningsinställningar</h1>
				<MDBBreadcrumb>
					<MDBBreadcrumbItem> 
						<h4>Välj förening:</h4>   
						<MDBSelect onChange={handleChange}
							data={forening_data}
						/>
					</MDBBreadcrumbItem>
				</MDBBreadcrumb>
        <MDBRow> 
          <MDBCol md='4'>
            <MDBCard className='hover-shadow rounded-4 rounded bg-primary border border-primary h-100'>
              <Link style={{ color: '#000' }} to={"user-admin/" + selectedAssociaion}>
								<MDBRow className='g-0'>
										<MDBCardBody className='text-white'>
											<MDBCardTitle>Medlemmar</MDBCardTitle>
											<MDBCardText>
												Här kan du administrera medlemmar, återställa lösenord, hantera licenser mm.
											</MDBCardText>
										</MDBCardBody>
                </MDBRow>
              </Link>
            </MDBCard>
          </MDBCol>
          <MDBCol md='4'>
						<MDBCard className='hover-shadow rounded-4 rounded bg-primary border border-primary h-100'>
              <Link style={{ color: '#000' }} to={"facilities/" + selectedAssociaion}>
                <MDBRow className='g-0'>
									<MDBCardBody className='text-white'>
										<MDBCardTitle>Anläggningar/skjutbanor</MDBCardTitle>
										<MDBCardText>
											Här kan du administrera anläggningar och skjutbanor.
										</MDBCardText>
									</MDBCardBody>
                </MDBRow>
              </Link>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </>
  );
}