import React, { useState, useEffect } from 'react';
import {useNavigate } from "react-router-dom";
import { 
  MDBCol,
  MDBDatatable,
  MDBBadge, 
  MDBRow,
  MDBCard,
  MDBCardHeader,
  MDBListGroup,
  MDBListGroupItem, 
  MDBBtn} from 'mdb-react-ui-kit';
import { BorderHeadline } from '../../../modules/BorderHeadline';

export default function AssociationLicenses(params) {

  const navigate = useNavigate();
	const userData = params.asyncData ? params.asyncData.data.data : null;
	const loading = params.loading;
	const association = params.association;
  const [selectedList,setSelectedList] = useState();
  const [userList,setUserList] = useState();

	const [tableData, setTableData] = useState({
		columns: [
			{ label: "Förnamn", field: "first_name"},
      { label: "Efternamn", field: "last_name"},
      { label: "E-post", field: "email"},
      { label: "Licensstatus", field: "license_status"},
			{ label: "Skyttekortstatus", field: "skyttekort_status"}
		],
		rows: []
	})

  const fixObject = (id, first_name, last_name, email, license_status, skyttekort_status) => { 
    return { 
      id: id, 
      first_name: first_name, 
      last_name: last_name, 
      email: email, 
      license_status: license_status,
			skyttekort_status: skyttekort_status}};

  function getColor(status){
    switch (status) {
      case 'ACTIVE':
        return 'success';
      case 'PENDING' || 'EXPIRED':
        return 'warning';
      case ('DELTED' || 'DISABLED'):
        return 'danger';
      default:
        return 'danger';
    }

  }

  const onSelectRow = (row,index) => {
    const list = index.map(i => {
      return <MDBListGroupItem key={tableData.rows[i].id}>{tableData.rows[i].first_name} {tableData.rows[i].last_name}</MDBListGroupItem>
    })
    setUserList(index.map(i => {
      return userData.find(obj => obj.id == tableData.rows[i].id);
    }))
    setSelectedList(list);
  }

	useEffect(() => {
		if(!loading){
			const userRows = userData.map(user => {

				let license = {
          status: "INACTIVE"
        }
				let skyttekort = {
          status: "INACTIVE"
        }
        if(user.licenser[0]){
          license.status = user.licenser[0].status
        }
				if(user.skyttekort[0]){
          skyttekort.status = user.skyttekort[0].status
        }
        
        Object.keys(user).map((key) =>{
          if(user[key] == null){
            user[key] = "";
          }
        })
				const fixedUser = fixObject(
					user.id, 
					user.first_name,
					user.last_name,
					user.email,
					license.status,
					skyttekort.status);
		
				return fixedUser;
				
			},);

			setTableData({
				columns: tableData.columns,
				rows: userRows.map((row) => {
					return {
						...row,
						license_status: (
							<>
								<MDBBadge color={getColor(row.license_status)} pill>
									{(row.license_status !== 'ACTIVE' && row.license_status !== 'PENDING') ? 'INACTIVE' : row.license_status}
								</MDBBadge>
							</>
						),
						skyttekort_status: (
							<>
								<MDBBadge color={getColor(row.skyttekort_status)} pill>
									{row.skyttekort_status !== 'ACTIVE' ? 'Saknas' : 'Aktiv'}
								</MDBBadge>
							</>
						)
					}
				})})
		}
	}, [userData]);

  return (
    <MDBRow>
      <MDBCol className='col-md-9 square border border-primary rounded-6 p-4 mx-1 position-relative'>
				<BorderHeadline headline="Medlemmar"/>
        <MDBDatatable 
            selectable
            mode='modal'
            data={tableData}
						isLoading={loading} 
						loadingMessage={"Laddar medlemmar..."} 
            multi
						bordered
						sm
						striped
						hover
            search
            onSelectRow={(row,i) => {
              onSelectRow(row,i)
            }} />
      </MDBCol>
      <MDBCol className='col-md-2.5 square border border-primary rounded-6 p-4 mx-1 position-relative'>
				<BorderHeadline headline="Valda medlemmar"/>
        <MDBCard>
          <MDBListGroup>
            {selectedList}
          </MDBListGroup>
          <MDBBtn onClick={() => {navigate("/step-license",{state: userList})}}>Vidare</MDBBtn>
        </MDBCard>
      </MDBCol>
    </MDBRow>
  );
}