import React, { useState, useRef } from 'react';
//import type { Location, useMatches } from "react-router-dom";
import {
  Outlet,
  ScrollRestoration,
  Location,
  useMatches
} from "react-router-dom";
import Icon_Svsf from "../svg/svg_icon_svsf";
import Sidenav from "../modules/Sidenav_Event";
import Navbar from "../modules/Navbar";
export const loader = async ({ params }) => {
  const resJson = [];

  return resJson;
};

export default function Root() {
  const [showOnLoggedin, setshowOnLoggedin] = useState("");
  const [hideOnLoggedin, setHideOnLoggedin] = useState("d-none");
  const [loggedin, setLoggedIn] = useState(() => {

    const saved = localStorage.getItem("key_id");


    if (saved) {
      setshowOnLoggedin("");
      setHideOnLoggedin("d-none");
      return true;
    } else {
      setshowOnLoggedin("d-none");
      setHideOnLoggedin("");
      return false;
    }
  });

  const [basicOpen, setBasicOpen] = useState(true);

  return (
    <>
      <header>
        <Navbar updateSidenav={setBasicOpen} sidenavState={basicOpen} showOnLoggedin={showOnLoggedin} hideOnLoggedin={hideOnLoggedin} />
      </header>
      <Outlet />
      <ScrollRestoration
        getKey={(location, matches) => {
          // default behavior
          console.log('location',location);
          return location.key;
        }}
      />
    </>
  );
}
