import React, { useState, useRef, useEffect } from 'react';
import {
  MDBSideNav,
  MDBSideNavMenu,
  MDBSideNavItem,
  MDBSideNavLink,
  MDBSideNavCollapse,
  MDBIcon,
  MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCheckbox,
  MDBCol,
  MDBDatepicker,
  MDBSelect,
  MDBInputGroup,
  MDBRadio,
  MDBInput,
  MDBValidationItem,
  MDBValidation,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBCardHeader,
  MDBAccordion,
  MDBAccordionItem,
  MDBCardSubTitle,
  MDBDatatable,
  MDBBadge,
  MDBModal,
  MDBModalHeader,
  MDBModalDialog,
  MDBModalContent,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBTextArea,
  MDBSwitch,
  MDBPopconfirm,
  MDBPopconfirmMessage,
  MDBNavbar,
  MDBTooltip,
  MDBDateTimepicker,
  MDBTimepicker,
  MDBTable,
  MDBDropdown,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBDropdownToggle,
  MDBPopover,
  MDBPopoverBody,
  MDBPopoverHeader
} from 'mdb-react-ui-kit';
import { useParams, useNavigate, NavLink, Link } from "react-router-dom";
import { fetch_get, fetch_post, fetch_delete } from "../../../services/common";


const customWeekdaysNarrow = ['S', 'M', 'T', 'O', 'T', 'F', 'L'];
const customMonthsFull = ['Januari', 'Februari', 'Mars', 'April', 'Maj', 'Juni', 'Juli', 'Augusti', 'September', 'Oktober', 'November', 'December',];
const customWeekdaysShort = ['Sön', 'Mån', 'Tis', 'Ons', 'Tor', 'Fre', 'Lör'];
const customMonthsShort = ['Jan', 'Feb', 'Mar', 'Apr', 'Maj', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'];


export default function EventEditStartlista({ params }) {
  // https://dev.svsf-ta.se/api/app/event/rounds/53a6c57f-377e-11ee-9385-00505692ffcc

  const { id } = useParams();
  const [data, setData] = useState([]);
  const [data_deltagare, setDataDeltagare] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [loading, setLoading] = useState(true);
  const [confirm_replace, setConfirmReplace] = useState(null);
  const [deltagar_button, setDeltagarButton] = useState(false);
  const [lov_skjutlag, setLovSkjutlag] = useState([]);
  const [selected_checkbox, setSelectedCheckbox] = useState(null);
  const [skjutlag, setSkjutlag] = useState(null);

  const [basicModal, setBasicModal] = useState(false);
  const toggleOpen = () => setBasicModal(!basicModal);

  const [basicModalSkjutlag, setBasicModalSkjutlag] = useState(false);
  const toggleOpenSkjutlag = () => setBasicModalSkjutlag(!basicModalSkjutlag);

  const [formValue, setFormValue] = useState({});
  const [formValueSkjuttid, setFormValueSkjuttid] = useState({});

  useEffect(() => {

    fetch_get('app/event/lov-skjutlag/' + id)
      .then(data => {
        //data.data.unshift({ "value": "", "text": "-" });
        //console.log('team-lov', data.data);
        setLovSkjutlag(data.data);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setLoading(false);
      });


  }, [loading]);

  useEffect(() => {




    if (skjutlag) {
      fetch_get('app/event/skjutlag/' + id + '/' + skjutlag)
        .then(data => {
          setSelectedCheckbox(null);
          setData(data.data);
        })
        .catch(error => {
          console.error('Error fetching data:', error);
        });

      fetch_get('app/event/deltagare-not-in-skjutlag/' + id + '/' + skjutlag)
        .then(data => {
          setDataDeltagare(data.data);
        })
        .catch(error => {
          console.error('Error fetching data:', error);
        });
    }

  }, [skjutlag, loading]);


  const mainStyles = {
    rubriker: {
      height: 20,
      position: 'absolute',
      top: '-10px',

    },
  };

  const rows_deltagare = {
    columns: [
      { label: '', field: 'test', sort: false },
      // { label: '-', field: 'forening_name', sort: true },
    ],
    rows: data_deltagare.map((row) => {
      return {
        ...row,
        add_button: (
          <>
            <MDBBtn outline size='sm' floating className='call-btn me-4' disabled={!selected_checkbox} onClick={() => onadddeltagare(row.id, row.full_name)}>
              <MDBIcon fas icon="angle-left" />
            </MDBBtn> {row.full_name}
          </>
        ),
        test: (
          <>
            <div className='d-flex justify-content-center'>
              <strong className="d-inline-flex bd-highlight flex-column">
                <MDBBtn outline size='sm' floating className='call-btn me-2 mb-2' disabled={!selected_checkbox} onClick={() => onadddeltagare(row.id, row.full_name)}>
                  <MDBIcon fas icon="angle-left" />
                </MDBBtn>

                <MDBPopover color='none'
                  btnClassName='btn btn-sm shadow-none'
                  btnChildren={<MDBIcon color='info' far icon="comment-dots" />}
                  placement='top'
                  dismiss
                  poperStyle={{width:450}}
                  className='border-primary'
                >
                  
                  <MDBPopoverBody>
                    <span className="d-inline-flex bd-highlight flex-column  flex-fill px-3">
                      <span className="fw-bolder fs-5">{row.full_name}</span>
                      <span>{row.forening_name}</span>
                      <span className='text-truncate'>{row.distrikt_name}</span>
                      <span>{row.klass_name_display}</span>
                      <span className="fst-italic fw-bold">Skapad:</span>
                      <span>{row.add_date} ({row.add_by_full_name})</span>
                      <span className="fst-italic fw-bold">Kommentar/Önskemål:</span>
                      <span>{row.comment}</span>
                    </span>
                  </MDBPopoverBody>
                </MDBPopover>
              </strong>
              <span className="d-inline-flex bd-highlight flex-column  flex-fill px-3">
                <strong>{row.full_name}</strong>
                <span>{row.forening_name}</span>
                <span>{row.klass_name_display}</span>
              </span>


            </div>
          </>
        ),
      };
    }),
  };

  const advancedData = {
    columns: [
      { label: ' ', field: 'delete_button', sort: false, width: 50 },
      { label: '#', field: 'tavla', sort: true, width: 20 },
      { label: 'Namn', field: 'full_name', sort: true, width: 160 },
      { label: 'Förening', field: 'forening_name', sort: true, width: 160 },
      { label: 'Klass', field: 'klass_name_display', sort: true, width: 80 },
    ],
    rows: data.map((row) => {

      return {
        ...row,
        delete_button: (
          <>
            {row.deltagare_id && (
              <MDBPopconfirm
                //outline
                //size='sm'
                //floating
                style={{ padding: '5px' }}
                color="none"
                modal
                btnChildren={<MDBIcon far icon="trash-alt" color="danger" size='sm' />}
                //<MDBIcon far icon="trash-alt" color="danger" size='sm' />
                cancelBtnText='Avbryt'
                onConfirm={() => do_update_startlista(row.id, null)}
                cancelBtnClasses='btn-secondary'
                btnClassName='btn btn-link'
                title={"Ta bort deltagare"}
              >
                <MDBPopconfirmMessage
                  icon={<MDBIcon className='me-2' far icon="trash-alt" color='danger' />}
                  size='lg'
                >
                  Ta bort deltagare från startlistan: <br /><br /><span className="fw-bold">{row.full_name}</span><br />
                  {row.forening_name}?<br /><br />
                  <span className="fw-bold">{row.full_name}</span> kommer att flyttas tillbaka till listan för anmälda.<br />
                </MDBPopconfirmMessage>
              </MDBPopconfirm>

            )}
          </>
        ),
      };
    })
  };


  const reset_active_checkbox = async () => {

    // När vi byter skjutlag och checkbox är markerad, då försvinner den inte av sig själv, utan blir kvar
    // Koden nedan går igenom aktiva checkbox'ar och gör en manuell "click"
    // Denna kan behövas ändras om man har flera objekt som har tr.active, t.ex. om man har flera DataTable

    const q_table = document.querySelectorAll(".table-startlista");
    //console.log('q_table', q_table);

    // Hämtar all tr som är aktiva (den eller de checkbox'ar som är i klickade)
    const q_test = document.querySelectorAll("tr.active");
    //console.log('q_test', q_test);
    // Stegar igenom dem
    for (var i = 0, len = q_test.length; i < len; i++) {
      // Hämtar alla input:checkbox
      const list = q_test[i].querySelectorAll("input[type=checkbox]");
      // Stegar igenom dem
      for (var j = 0, len = list.length; j < len; j++) {
        // Gör en manuell "click"
        list[i].click();

        // Är det så vi har flera checkboxar, så bborde vi avbryta efter först........

      };

    };

  }

  const do_update_startlista = async (startlist_id, deltagare_id) => {

    await reset_active_checkbox();

    const form_params = {
      "action": "event_startlist_update",
      "startlist_id": startlist_id,
      "db_deltagare_id": deltagare_id
    };

    const resJson = await fetch_post('app/event/do_submit/' + id, form_params);
    setLoading(true);


  }

  const onChangeSkjutlag = async (val) => {
    await reset_active_checkbox();
    setSkjutlag(val.value);
    setSelectedCheckbox(null);
  }






  const onadddeltagare = async (start_id, start_full_name) => {

    // checkbox är vald för det deltagaren ska flyttas
    if (selected_checkbox) {
      console.log('onadddeltagare selected', start_id, selected_checkbox);

      // Koll om det redan ligger en deltagare på platsen
      if (selected_checkbox.deltagare_id) {
        // Det finns redan en deltagare, då skickar vi upp en popconfirm om de vill flytta undan den.
        setConfirmReplace({
          "startlist_id": selected_checkbox.id,
          "start_full_name": start_full_name,
          "deltagare_id": start_id,
          "full_name": selected_checkbox.full_name,
          "forening_name": selected_checkbox.forening_name
        });

      } else {
        // Det finns ingen där, då kan vi flytta dit den nya direkt
        do_update_startlista(selected_checkbox.id, start_id);

      }

    } else {
      console.log('onadddeltagare', start_id);

    }
  };

  return (
    <>

      <MDBContainer className="py-4">

        <MDBNavbar light bgColor='light'>

          <MDBSelect
            style={{width: '399px'}}
            label="Välj skjutlag"
            data={lov_skjutlag}
            preventFirstSelection
            clearBtn
            visibleOptions={8}
            onValueChange={(e) => onChangeSkjutlag(e)}
          />


          <MDBDropdown>
            <MDBDropdownToggle color='secondary'>Skriv ut / Export</MDBDropdownToggle>
            <MDBDropdownMenu>
              <MDBDropdownItem link color='light'><MDBIcon fas icon="file-pdf" className='me-2' />Aktuellt lag (PDF)</MDBDropdownItem>
              <MDBDropdownItem link color='light'><MDBIcon fas icon="file-pdf" className='me-2' />Alla lag (PDF)</MDBDropdownItem>
              <MDBDropdownItem link>Sius: shooter.csv</MDBDropdownItem>
              <MDBDropdownItem link>Megalink: export.txt</MDBDropdownItem>
            </MDBDropdownMenu>
          </MDBDropdown>
        </MDBNavbar>

        <MDBRow className='g-3 py-3'>
          <MDBCol className='col-md-12 col-sm-12'>
            <MDBRow className='g-3'>
              <MDBCol className='col-md-7 col-sm-12 square border border-primary rounded-6 p-4 mx-1 position-relative'>

                <MDBBadge light className="position-absolute" style={mainStyles.rubriker}>Startlista</MDBBadge>

                <MDBDatatable
                  className='table-startlista'
                  //search
                  bordered
                  sm
                  striped
                  hover
                  sortField='tavla'
                  pagination={false}
                  entries={1000}
                  noFoundMessage='Välj skjutlag...'
                  loaderClass='Laddar...'
                  ofText='av'
                  rowsText='Rader per sida:'
                  selectable
                  data={advancedData}
                  // multi
                  onRowClick={(e) => {
                    console.log('onRowClick', e);
                  }}

                  onSelectRow={(e) => {
                    if (e[0]) {
                      //console.log('selected', e[0].id, e)
                      setSelectedCheckbox(e[0]);
                    } else {
                      setSelectedCheckbox(null);
                    }

                  }}
                  format={(field, value) => {
                    if (field === 'full_name' || field === 'forening_name' || field === 'klass_name_display') {
                      if (value === 'null') {
                        return { backgroundColor: '#E1F5FE', fontWeight: 400 };
                      } else {
                        return { backgroundColor: '#B2DFDB', fontWeight: 400 };
                      }
                    }
                  }}
                />
              </MDBCol>
              <MDBCol className='col-md-4 col-sm-12 square border border-primary rounded-6 p-4 mx-1 position-relative'>

                <MDBBadge light className="position-absolute" style={mainStyles.rubriker}>Anmälda deltagare</MDBBadge>
                <MDBDatatable
                  //className='pb-4'
                  //search
                  bordered
                  //sm
                  striped
                  hover
                  maxHeight='560px'
                  sortField='full_name'
                  pagination={false}
                  entries={1000}
                  noFoundMessage={'Ingen deltagare att lägga in i skjutlagen...'}
                  loaderClass='Laddar...'
                  ofText='av'
                  rowsText='Rader per sida:'
                  data={rows_deltagare}
                />
              </MDBCol>

            </MDBRow>
          </MDBCol>


        </MDBRow>



        <MDBPopconfirm
          open={confirm_replace}
          btnChildren='DEFAULT'
          modal
          btnClassName="visually-hidden"
          cancelBtnText='Avbryt'
          onConfirm={() => {
            do_update_startlista(confirm_replace.startlist_id, confirm_replace.deltagare_id,);
            setConfirmReplace(null);
          }}
          onCancel={() => setConfirmReplace(null)}
          cancelBtnClasses='btn-secondary'
          title={"zzzzzzzzzz"}
        >
          {confirm_replace && (<MDBPopconfirmMessage icon={<MDBIcon icon='question-circle' color='danger' className='me-2' />} >
            Platsen är upptagen!<br /><br />
            Vill du byta ut: <br />
            <span className="fw-bold">{confirm_replace.full_name}</span> med <br />
            <span className="fw-bold">{confirm_replace.start_full_name}</span>?<br /><br />
            <span className="fw-bold">{confirm_replace.full_name}</span> kommer att flyttas tillbaka till listan för anmälda.<br />
          </MDBPopconfirmMessage>)}

        </MDBPopconfirm>


      </MDBContainer >

    </>
  );
}

