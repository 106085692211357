import React, { useState, useEffect } from 'react';
import { useNavigate, useRoutes } from "react-router-dom";
import { 
  MDBCol,
  MDBDatatable,
  MDBBadge,
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBRow,
	MDBDropdown,
	MDBDropdownMenu,
	MDBDropdownItem,
	MDBDropdownToggle,
	MDBCardHeader,
	MDBListGroup,
	MDBListGroupItem,
	MDBAlert,
	MDBIcon} from "mdb-react-ui-kit";
import { createPortal } from "react-dom";
import { BorderHeadline } from '../../../modules/BorderHeadline';
import { fetch_json,fetch_post,fetch_get } from "../../../../services/common";

export default function AssociationUsers(params) {

	const userData = params.asyncData ? params.asyncData.data.data : null;
	const loading = params.loading;
	const navigate = useNavigate();
	const association = params.association;
	const [userAdmins,setUserAdmins] = useState(params.admins);
	const [adminAddedAlert, setAdminAddedAlert] = useState(false)
	const [adminRemovedAlert, setAdminRemovedAlert] = useState(false)
	const [tableData, setTableData] = useState({
		columns: [
			{ label: "Förnamn", field: "first_name"},
			{ label: "Efternamn", field: "last_name"},
			{ label: "E-post", field: "email"},
			{ label: "Licensstatus", field: "license_status"},
			{ label: "Licens start", field: "license_start_date"},
			{ label: "Licens stopp", field: "license_end_date"},
			{ label: "", field: "actions",fixed: 'right', sort: false }
		],
		rows: []
	})

	const fixObject = (id, first_name, last_name, email, license_status, license_start_date, license_end_date, actions,roles, key_id) => { 
    return { 
      id: id, 
      first_name: first_name, 
      last_name: last_name, 
      email: email, 
      license_status: license_status, 
      license_start_date: license_start_date, 
      license_end_date: license_end_date,
      actions: actions,
			roles: roles,
			key_id: key_id}};

	useEffect(() => {
		if(!loading){
			const userRows = userData.map(user => {
				let roles = []
		
				let license = {
					status: "INACTIVE",
					start_date: "-",
					end_date: "-"
				}
				if(user.licenser && user.licenser[0]){
					license.status = user.licenser[0].status
					license.end_date = user.licenser[0].end_date
					license.start_date = user.licenser[0].start_date
				}
				if(user.roles){
					user.roles.map(role => {
						roles.push(role)
					})
				}
				
				Object.keys(user).map((key) =>{
					if(user[key] == null){
						user[key] = "";
					}
				})
				const fixedUser = fixObject(
					user.id,
					user.first_name,
					user.last_name,
					user.email,
					license.status,
					license.start_date,
					license.end_date,
					"",
					roles,
					user.key_id);
		
				return fixedUser;
				
			},);
			setTableData({
				columns: tableData.columns,
				rows: userRows.map((row) => {
					return {
						...row,
						license_status: (
							<>
								<MDBBadge color={getColor(row.license_status)} pill>
									{(row.license_status !== 'ACTIVE' && row.license_status !== 'PENDING') ? 'INACTIVE' : row.license_status}
								</MDBBadge>
							</>
						),
						actions: (
							<MDBDropdown dropleft> 
								<MDBDropdownToggle size='sm' style={{background: 'transparent', boxShadow: 'none'}}><MDBIcon style={{ color: '#0065A4'}} fas icon="ellipsis-h" /></MDBDropdownToggle>
								{createPortal(
									<MDBDropdownMenu >
										<MDBDropdownItem link childTag='button' onClick={() => {navigate("/user-details/"+row.key_id)}}>
											<h6>Visa profil</h6>
										</MDBDropdownItem>
										<MDBDropdownItem link childTag='button' onClick={() => toggleShowRoles(row.id)}>
											<h6>Roller</h6>
										</MDBDropdownItem>
									</MDBDropdownMenu>
									,document.body)}
							</MDBDropdown>
						)
		
					}
				})})
		}
	}, [userData]);
  
  const [editModalRoles, setEditModalRoles] = useState(false);
  const [editUserForm, setEditUserForm] = useState({
    id: "", 
    first_name: "", 
    last_name: "", 
    email: "", 
    license_status: "", 
    license_start_date: "", 
    license_end_date: "",
    actions: "",
		roles: "",
		key_id: ""
  });
/* 
  const onSubmit = (e) => {
    e.preventDefault();
    if(e.target.checkValidity()){
      const newRows = userData.rows.map(user => {
        if(user.id === editUserForm.id){
          return {
            ...user,
            first_name: editUserForm.first_name,
            last_name: editUserForm.last_name,
            email: editUserForm.email,
						roles: editUserForm.roles
          }
        }
        else return user;
      });
      setUserData({ ...userData, rows: newRows});
    }
  } */

	async function addAdmin(key_id){
		if(key_id){
			const jsonObj = {
			action: "add_admin",
			db_role_id:300,
			db_forening_id:association.id,
			db_distrikt_id:0,
			db_gren_id:0
			}
			const resJson = await fetch_post('app/user/do_submit/' + key_id, jsonObj);
			setUserAdmins([...userAdmins, {
				forening_id: association.id,
				forening_name: association.name,
				name: editUserForm.first_name + " " + editUserForm.last_name,
				user_id: editUserForm.id,
				user_key_id: key_id
			}]);
			const updUser = await fetch_json("app/user/all/" + key_id,'GET');
			const index = tableData.rows.findIndex(obj => obj.id == updUser.data.id);
			tableData.rows[index].roles = updUser.data.roles;
			setEditUserForm({...editUserForm, ["roles"]:updUser.data.roles})
			setAdminAddedAlert((prev) => !prev);
		}
		setEditModalRoles(!editModalRoles);
		
	}

	async function removeAdmin(key_id){
		if(key_id){
			const jsonObj = {
				action: "remove_admin",
				key_id: key_id,
				role_id: 300
			}
			const resJson = await fetch_post('app/forening/do_submit/' + association.id, jsonObj);
			setUserAdmins(resJson.msg);
			const updUser = await fetch_json("app/user/all/" + key_id,'GET');
			const index = tableData.rows.findIndex(obj => obj.id == updUser.data.id);
			tableData.rows[index].roles = updUser.data.roles;
			setEditUserForm({...editUserForm, ["roles"]:updUser.data.roles})
			setAdminRemovedAlert((prev) => !prev);
		}
		setEditModalRoles(!editModalRoles)
		
	}

  const toggleShowRoles = (user_id) => {
		if(tableData.rows.length > 0){
			if(!editModalRoles){
				const newRow = tableData.rows.find(obj => obj.id == user_id);
				setEditUserForm(newRow);
			}
			setEditModalRoles(!editModalRoles)
		};
	}

  const onChange = (e) => {
    setEditUserForm({ ...editUserForm, [e.target.name]: e.target.value });
  };

	function isAdmin(key_id){
		const admin = userAdmins.filter((user) => (user.user_key_id === key_id))
		if(admin.length > 0){
			return true
		} else {
			return false;
		}
	}

  function getColor(status){
    switch (status) {
      case 'ACTIVE':
        return 'success';
      case 'PENDING':
        return 'warning';
      case ('EXPIRED' || 'DELTED' || 'DISABLED'):
        return 'danger';
      default:
        return 'danger';
    }

  }

  return (
    <>
			<MDBAlert
				color='primary'
				autohide
				position='top-right'
				delay={4000}
				appendToBody
				open={adminAddedAlert}
				onClose={() => setAdminAddedAlert(false)}
			>
				{editUserForm ? <>{editUserForm.first_name} {editUserForm.last_name} har lagts till som administratör för {association.name}.</> : null}
			</MDBAlert>
			<MDBAlert
				color='primary'
				autohide
				position='top-right'
				delay={4000}
				appendToBody
				open={adminRemovedAlert}
				onClose={() => setAdminRemovedAlert(false)}
			>
				{editUserForm ? <>{editUserForm.first_name} {editUserForm.last_name} har tagits bort som administratör för {association.name}.</> : null}
			</MDBAlert>
			<MDBRow>
				<MDBCol className='col-md-9.5 square border border-primary rounded-6 p-4 mx-1'>
					<MDBModal open={editModalRoles} setOpen={setEditModalRoles} tabIndex='-1'>
						<MDBModalDialog>
							<MDBModalContent>
								<MDBModalHeader>
									<MDBModalTitle>Roller</MDBModalTitle>
									<MDBBtn className='btn-close' color='none' onClick={toggleShowRoles}></MDBBtn>
								</MDBModalHeader>
								{/* <MDBValidation onSubmit={onSubmit} className='row g-3'> */}
									<MDBModalBody>
										<MDBRow className='g-3'>
										<MDBCardHeader><strong>{editUserForm.first_name} {editUserForm.last_name}</strong></MDBCardHeader>
											<MDBListGroup>
												{editUserForm.roles.length > 0 ? editUserForm.roles.map((role,index) => {
													switch(role.id){
														case '100':
															return <MDBListGroupItem key={index}>{role.name}</MDBListGroupItem>
														case '200':
															return <MDBListGroupItem key={index}>{role.name}</MDBListGroupItem>
														case '300':
															return <MDBListGroupItem key={index}>{role.name} - {role.forening_name}</MDBListGroupItem>
														case '400':
															return <MDBListGroupItem key={index}>{role.name} - {role.distrikt_name}</MDBListGroupItem>
														case '500':
															return <MDBListGroupItem key={index}>{role.name} - {role.gren_name}</MDBListGroupItem>
														default:
															return <MDBListGroupItem key={index}>{role.name}</MDBListGroupItem>
													}
												}) : null}
											</MDBListGroup>
										</MDBRow>
										{isAdmin(editUserForm.key_id) ? 
										<MDBRow>
											<MDBCol>
												<MDBBtn onClick={() => removeAdmin(editUserForm.key_id)} color='tertiary'>
													<MDBIcon fas icon="minus" />
													{' '}Ta bort som administratör för {association.name}
												</MDBBtn>
											</MDBCol>
										</MDBRow> : 
										<MDBRow>
											<MDBCol>
												<MDBBtn onClick={() => addAdmin(editUserForm.key_id)} color='tertiary'>
													<MDBIcon fas icon="plus" />
													{' '}Lägg till som administratör för {association.name}
												</MDBBtn>
											</MDBCol>
										</MDBRow>}
									</MDBModalBody>
									<MDBModalFooter>
										<MDBBtn type='reset' color='secondary' onClick={toggleShowRoles}>
											Stäng
										</MDBBtn>{/* 
										<MDBBtn type='submit'>Spara</MDBBtn> */}
									</MDBModalFooter>
								{/* </MDBValidation> */}
							</MDBModalContent>
						</MDBModalDialog>
					</MDBModal>
				 <MDBDatatable 
					mode='modal' 
					data={tableData} 
					isLoading={loading} 
					loadingMessage={"Laddar medlemmar..."} 
					multi
					search
					bordered
					sm
					striped
					hover/>
				</MDBCol>
				<MDBCol className='col-md-2 square border border-primary rounded-6 p-4 mx-1 position-relative'>
					<BorderHeadline headline="Administratörer"/>
						<MDBListGroup>
							{userAdmins.map((admin,index)=> {
								return <MDBListGroupItem className='text-center' key={index}>{admin.name}</MDBListGroupItem>
							})}
						</MDBListGroup>
				</MDBCol>
			</MDBRow>
    </>
  );
}