import React, { useState, useEffect } from 'react';
// import { fetch_json } from "../../components/common";

export const ListDeltagare = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Här kan du använda fetch() eller något HTTP-bibliotek som Axios för att hämta data från din REST API.
    // I det här exemplet antar jag att API:et returnerar data som en array av objekt.
    fetch('https://dev.svsf-ta.se/api/app/event/all/53a6c57f-377e-11ee-9385-00505692ffcc')
      .then(response => response.json())
      .then(data => {
        setData(data);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setLoading(false);
      });
  }, []);

  return (
    <div>
      <h1>Data från API:</h1>
      {loading ? (
        <p>Laddar data...</p>
      ) : (
        <ul>
          {data.data.deltagare.map(item => (
            <li key={item.id}>{item.first_name} {item.last_name}</li>
            // Du måste ersätta "id" och "name" med de faktiska datanamnen från din API-respons.
          ))}
        </ul>
      )}

    </div>
  );
};

export const Test = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Här kan du använda fetch() eller något HTTP-bibliotek som Axios för att hämta data från din REST API.
    // I det här exemplet antar jag att API:et returnerar data som en array av objekt.
    fetch('https://dev.svsf-ta.se/api/app/event/all/53a6c57f-377e-11ee-9385-00505692ffcc')
      .then(response => response.json())
      .then(data => {
        setData(data);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setLoading(false);
      });
  }, []);

  return (
    <div>
      <h1>Data från API:</h1>
      {loading ? (
        <p>Laddar data...</p>
      ) : (
        <ul>
          {data.data.deltagare.map(item => (
            <li key={item.id}>{item.first_name} {item.last_name}</li>
            // Du måste ersätta "id" och "name" med de faktiska datanamnen från din API-respons.
          ))}
        </ul>
      )}

    </div>
  );
};

