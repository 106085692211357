import React from "react";
import { createRoot } from "react-dom/client";
//import "./index.css";
import './scss/index.scss';
import "mdb-react-file-upload/dist/css/file-upload.min.css";
import "mdb-react-table-editor/dist/css/table-editor.min.css";
import 'mdb-react-treeview/dist/css/treeview.min.css';
import 'mdb-react-treetable/dist/css/treetable.min.css';
import "@fortawesome/fontawesome-free/css/all.min.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

const container = document.getElementById("root");
const root = createRoot(container);

root.render(

  <React.StrictMode>
    <App />
  </React.StrictMode>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
