import React,{useState} from 'react';
import { useLoaderData, Link } from "react-router-dom";
import { fetch_json } from "../../../services/common";
import {
  MDBContainer,
  MDBCard,
  MDBBreadcrumb,
	MDBBreadcrumbItem,
  MDBCardBody,
  MDBCardText,
  MDBCardTitle,
  MDBRow,
  MDBCol,
  MDBCardImage,
	MDBSelect
} from 'mdb-react-ui-kit';
import jwt_decode from "jwt-decode";

export async function loader({ params }) {
  
  const data = localStorage.getItem("token");
  var decoded = "";
  if (data) {
    decoded = jwt_decode(data);
  }
    const obj = await fetch_json("app/user/all/" + decoded.kid);
    return obj;
}


export default function DistrictHome() {

  const loadObject = useLoaderData();
	const roles = loadObject.data.roles;
	const district = {
		id: null,
		name: ""
	}
	roles.map(role => {
		if(role.id == "400" && role.status == "ENABLED"){
			district.id = 560;
			district.name = role.distrikt_name;
		}
	})

  return (
    <>
      <MDBContainer className="py-4 p-3 bg-light bg-gradient rounded-5">    
				<MDBBreadcrumb>
					<MDBBreadcrumbItem>
						<h1>{district.name}</h1>
					</MDBBreadcrumbItem>
				</MDBBreadcrumb>
        <MDBRow> 
          <MDBCol md='4'>
            <MDBCard className='hover-shadow rounded-4 rounded bg-primary border border-primary h-100'>
              <Link style={{ color: '#000' }} to={"/district/associations/" + district.id}>
								<MDBRow className='g-0'>
									<MDBCardBody className='text-white'>
										<MDBCardTitle>Föreningar</MDBCardTitle>
										<MDBCardText>
											Här kan du administrera föreningar
										</MDBCardText>
									</MDBCardBody>
                </MDBRow>
              </Link>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </>
  );
}