import { useLoaderData,useNavigate,useLocation } from "react-router-dom";
import React, { useState } from 'react';
import {    
  MDBRow, 
  MDBValidation,
  MDBValidationItem,
  MDBSelect,
  MDBBtn, 
  MDBContainer,
  MDBDatepicker,
  MDBAlert,
  MDBCol,
  MDBCard,
  MDBListGroup,
  MDBListGroupItem,
  MDBCardHeader} from 'mdb-react-ui-kit';
import NotFound404 from "../NotFound404";

export default function LicenseShop(obj) {

	const location = useLocation();
  const users = location.state;
	console.log(users)

  const sport = obj.sport;
  const events = obj.events;
  const licenses = obj.licenses;
  const user = obj.user;
  const navigate = useNavigate();
  const [showOptionalInputs, setShowOptionalInputs] = useState(false);
  const [disableStartDate, setDisableStartDate] = useState(true)
  const [disableEndDate, setDisableEndDate] = useState(true)
  const [eventValidation, setEventValidation] = useState(false)
  const [minDate, setMinDate] = useState(null);
  const dayInMS = 86400000;  
  const pricePerDay = Number(licenses.find(obj => obj.value == 8).amount);
  const userAge = new Date().getFullYear() - new Date(user.birth_date).getFullYear();
  const currentDate = newDate(new Date(new Date().getFullYear(),new Date().getMonth(),new Date().getDate()));
  const [showAlert,setShowAlert] = useState(false);
  
  const getLatestStartDate = () => {
    var latestDate = currentDate
    const userLicenses = user.licenser;
    console.log(userLicenses)
    if(userLicenses.length > 0){
      userLicenses.map(element => {
        if(element.status !== "INACTIVE"){
          setShowAlert(true);
          if(formatDate(latestDate).getTime() < formatDate(element.end_date).getTime()){
            latestDate = element.end_date;
          }
        }
      });
    }
    var date = new Date(latestDate);
    if(latestDate != currentDate){
      date.setDate(date.getDate() + 1);
    }
    return newDate(date)
  }

  const [formValue, setFormValue] = useState({
    license: null,
    additionalLicense: "-",
    sport: null,
    event: null,
    startDate: "",
    endDate: "",
    price: 0,
    age: (userAge > 20) ? "Senior" : "Junior",
    user: user.id
  });

  function padDigits(num) {
    return num.toString().padStart(2, '0');
  }
  
  function newDate(date) {
    return [
      date.getFullYear(),
      padDigits(date.getMonth() + 1),
      padDigits(date.getDate()),
    ].join('-');
  }

  function adjustDate(licenseID,fromDate){
    var date = new Date(fromDate);
    licenses.map(license => {
      if((license.id == licenseID) && (license.rolling_periods != null)){
        date.setMonth(date.getMonth()+Number(license.rolling_periods));
      }
      if((license.id == licenseID) && license.fixed_end_date != null){
        date = new Date(license.fixed_end_date)
      }
    })
    return newDate(date);
  }

  function getLicensePeriod(licenseID){
    return licenses.find(obj => obj.value == licenseID ).id;
  }

  function getLicenseAmount(licenseID){
    return licenses.find(obj => obj.value == licenseID ).amount;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if(e.target.checkValidity()){
      navigate("/license-summary",{state: formValue});
    }
    
  };

  const calcPrice = (i) => {
    setFormValue(formValue => ({...formValue, ["price"]:pricePerDay*i}))
  }

  function calculateDays(startDate,endDate){
    if(startDate && endDate){
      if(startDate !== "" && startDate !== ""){
        return ((formatDate(endDate).getTime()+dayInMS)-(formatDate(startDate).getTime()))/dayInMS;
      }
      else return 0
    }
    else return 0
  }

  function formatDate(date){
    if(date != null){return new Date(date.split("/").reverse().join("-"));}
  }

  const onStartDateChange = (e) => {
    if(formValue.license){
      if(formValue.license.id == 8){
        if(e !== ""){
          const date = formatDate(e);
          date.setDate(date.getDate() - 1);
          setMinDate(new Date(date));
          if(!formValue.endDate){
            setFormValue(formValue => ({ ...formValue, ["startDate"]:e,["endDate"]: e }));  
            calcPrice(calculateDays(e,e));    
          }
          if(formValue.endDate){
            if(formatDate(e).getTime() > formatDate(formValue.endDate).getTime()){
              setFormValue(formValue => ({ ...formValue,["startDate"]:e, ["endDate"]: e }));
              calcPrice(calculateDays(e,e));
            }
            else calcPrice(calculateDays(e,formValue.endDate));
          }
        } else {
          setFormValue(formValue => ({ ...formValue,["startDate"]:getLatestStartDate() }));
        }
        if(showOptionalInputs){
          calcPrice(calculateDays(e,formValue.endDate));
        }
      } else {
        const newEndDate = adjustDate(getLicensePeriod(formValue.license.id),e);
        setFormValue({ ...formValue, ["startDate"]: e ,["endDate"]: newEndDate });  
      } 
    }
  };

  const onEndDateChange = (e) => {
    if(formValue.license){
      if(formValue.license.id == 8){
        if(e !== ""){
          setFormValue(formValue => ({...formValue, ["endDate"]:e}));
          calcPrice(calculateDays(formValue.startDate,e));
        }
        if(showOptionalInputs){
          calcPrice(calculateDays(formValue.startDate,e));
        } 
      }
    }
  };

  const onChange = (e) => {
    if(e.name === "license"){
      if(e.value == 8){
        getLatestStartDate()
        setDisableEndDate(false);
        setDisableStartDate(false);
        setShowOptionalInputs(true);
        setEventValidation(true);
        setFormValue(formValue =>({...formValue,
          [e.name]: {id:e.value,text:e.text},
          ["startDate"]: getLatestStartDate(),
          ["endDate"]: getLatestStartDate(),
          ["price"]: 0,
        }));
      }
      else{
        setEventValidation(false);
        setShowOptionalInputs(false);
        setDisableEndDate(true);
        const fixedStartDate = licenses.find(obj => obj.value == e.value).fixed_start_date;
        if(!fixedStartDate){
          setDisableStartDate(false);
          setFormValue({...formValue,
            [e.name]: {id:e.value,text:e.text},
            ["startDate"]: getLatestStartDate(),
            ["price"]: Number(getLicenseAmount(e.value)),
            ["endDate"]: adjustDate(e.value,getLatestStartDate())});
          
        } else {
          setDisableStartDate(true);
          setFormValue({...formValue,
            [e.name]: {id:e.value,text:e.text},
            ["startDate"]: fixedStartDate,
            ["price"]: Number(getLicenseAmount(e.value)),
            ["endDate"]: adjustDate(e.value,fixedStartDate)
          });
        }
      }
      
    } else {
      setFormValue({ ...formValue, [e.name]: {id:e.value,text:e.text} });
    }
  };

      //
  return (
      <MDBRow>
        <MDBAlert open={showAlert}>
          Aktiv licens finns redan, startdatum justeras efter när dina licenser går ut.
        </MDBAlert>
        <MDBCol md='12'>
          <MDBRow className='d-flex justify-content-center'>
            <MDBValidation onSubmit={handleSubmit} className='row g-3'>
              <MDBRow className='mb-3'>
                  <MDBSelect
                    className='col-md-3'
                    data={licenses}
                    label='Typ av licens'
                    name="license"
                    onValueChange={onChange}
                    preventFirstSelection
                    validation
                    validFeedback=''
                    invalidFeedback='Ange typ av licens'
                  />
                <MDBValidationItem className='col-md-3'>
                  <MDBSelect
                    label='Tilläggslicens'
                    data={[
                      { text: 'Ett', value: 1, name: "additionalLicense" },
                      { text: 'Två', value: 2, name: "additionalLicense"  },
                      { text: 'Tre', value: 3, name: "additionalLicense"  },
                    ]}
                    name="additionalLicense"
                    onValueChange={onChange}
                    preventFirstSelection
                  />
                </MDBValidationItem>
                  <MDBSelect
                    className='col-md-3'
                    label='Gren/diciplin'
                    data={sport}
                    name="sport"
                    validation
                    validFeedback=''
                    invalidFeedback='Ange en gren'
                    onValueChange={onChange}
                    preventFirstSelection
                  />
                <MDBValidationItem className='col-md-3'>
                  <MDBSelect
                    label="Åldersklass"
                    data={[
                      { text: formValue.age }
                    ]}
                    disabled
                  />
                </MDBValidationItem>
              </MDBRow>
              <MDBRow className='mb-3'>
                <MDBValidationItem className='col-md-3'>
                  <MDBDatepicker disablePast clearBtnText=''  format='yyyy-mm-dd' disabled inputToggle={disableStartDate} className='bg-white text-dark' onChange={onStartDateChange} required name="startDate" label='Startdatum' value={formValue.startDate}/>
                </MDBValidationItem>
                <MDBValidationItem className='col-md-3'>
                  <MDBDatepicker disablePast clearBtnText=''  format='yyyy-mm-dd' disabled inputToggle={disableEndDate} className='bg-white text-dark' onChange={onEndDateChange} min={minDate} required name="endDate" label='Slutdatum' value={formValue.endDate}/>
                </MDBValidationItem>
                <MDBValidationItem className={showOptionalInputs ? "d-block col-md-3" : "d-none"}>
                  <MDBSelect
                    label='Tävling'
                    data={events}
                    name="event"
                    validation={eventValidation}
                    validFeedback=''
                    invalidFeedback='Ange en gren'
                    onValueChange={onChange}
                    preventFirstSelection
                  />
                </MDBValidationItem>
              </MDBRow>
              <br/><hr/>
              <p>Beräknat pris: {formValue.price}kr</p>
              <br/><hr/>
              <div className='col-12 d-flex justify-content-center'>
                <MDBBtn type='submit'>Bekräfta</MDBBtn>
              </div>
            </MDBValidation>
          </MDBRow>
        </MDBCol>
      </MDBRow>
   );
}