import React, { useState } from "react";

import { Routes, Route, createBrowserRouter, createRoutesFromElements, useLoaderData, RouterProvider, Form } from "react-router-dom";
import {
  MDBContainer,
} from 'mdb-react-ui-kit';

import NotFound404 from "./components/views/NotFound404";
import ForgotPassword from "./components/views/ForgotPassword";
import LoginRegister from "./components/views/users/LoginRegister";
import Root, { loader as RootLoader } from "./components/views/Root";
import HomePage, { loader as HomePageLoader } from "./components/views/HomePage";
import RankingPage, { loader as RankingLoader } from "./components/views/Ranking/RankingPage";
import RankingPageList, { loader as RankingPageLoader } from "./components/views/Ranking/RankingPageList";
import TeamHome, { loader as TeamHomeLoader } from "./components/views/association/TeamHome";
import DistriktHome, { loader as DistriktHomeLoader } from "./components/views/distrikt/DistriktHome";
import GrenHome, { loader as GrenHomeLoader } from "./components/views/gren/GrenHome";
import TeamRoot, { loader as TeamRootLoader } from "./components/views/TeamRoot";
import SettingsHome from "./components/views/settings/SettingsHome";
import SettingsRoot, { loader as SettingsRootLoader } from "./components/views/settings/SettingsRoot";
import EventsPage, { loader as EventsPageLoader } from "./components/views/events/EventsPage";
import EventsHome, { loader as EventsHomeLoader } from "./components/views/events/EventsHome";
import EventNew, { action as EventNewAction, loader as EventNewLoader } from "./components/views/events/EventNew";
import EventNewEvenemang from "./components/views/events/EventNewEvenemang";
import EventEditStepper from "./components/views/events/EventEditStepper";
import EventEditStepper2 from "./components/views/events/EventEditStepper-2";
import EventEditSportgrenar, { loader as EventEditSportgrenarLoader } from "./components/views/events/EventEditSportgrenar";
import EventEditBetalning, { loader as EventEditBetalningLoader } from "./components/views/events/EventEditBetalning";
import EventEditLag from "./components/views/events/EventEditLag";
import EventEditDokument from "./components/views/events/EventEditDokument";
import EventEditExtra from "./components/views/events/EventEditExtra";
import EventAnmalan, { loader as EventAnmalanLoader } from "./components/views/events/EventAnmalan";
import EventsRoot, { loader as EventsRootLoader } from "./components/views/events/EventsRoot";
import EventRoot, { loader as EventRootLoader } from "./components/views/events/EventRoot";
import EventSettingsDiscipline from "./components/views/settings/event/EventSettingsDiscipline";
import EventSettingsRoot from "./components/views/settings/event/EventSettingsRoot";
import EventSettingsHome from "./components/views/settings/event/EventSettingsHome";
import EventCategories, { loader as EventCategoriesLoader } from "./components/views/settings/event/EventCategories";
import EventOverview, { loader as EventOverviewLoader } from "./components/views/events/EventOverviewPage";
import EventOverviewAdmin, { loader as EventOverviewAdminLoader } from "./components/views/events/EventOverviewAdminPage";
import DefEditSportgrenarKlasser from "./components/views/settings/event/DefEditSportgrenarKlasser";
import ListDistrict, { loader as listLoader, } from "./components/views/ListDistrict";
import AssociationDetails, { loader as associationLoader, } from "./components/views/association/administration/AssociationDetails";
import UserDetails, { loader as userLoader } from "./components/views/users/UserDetails";
import EditUser, { loader as editUserLoader } from "./components/views/EditUser";
import SearchUsers, { loader as searchUsersLoader } from "./components/views/SearchUsers";
import ShopSummary from "./components/views/license/ShopSummary";
import PaymentProcess from "./components/views/license/PaymentProcess";
import PaymentSuccessful from "./components/views/license/PaymentSuccessful";
import PaymentError from "./components/views/license/PaymentError";
import LicenseStepper, { loader as LicenseShopLoader } from "./components/views/license/LicenseStepper";
import LicenseShop from "./components/views/license/LicenseShop";
import TestDocuments from "./components/views/TestDocuments";
import TesterPage from "./components/views/TesterPage";
import FacilitiesHome, { loader as FacilitiesHomeLoader } from "./components/views/association/facilities/FacilitiesHome";
import FacilityDetails, { loader as FacilityDetailsLoader } from "./components/views/association/facilities/FacilityDetails";
import FacilityForm, { loader as FacilityFormLoader } from "./components/views/association/facilities/FacilityForm";
import DistrictHome, { loader as DistrictHomeLoader } from "./components/views/district/DistrictHome";
import DistrictAssociations, { loader as DistrictAssociationsLoader } from "./components/views/district/associations/DistrictAssociatons";
import WorkInProgress from "./components/views/WorkInProgress";

function ProjectsPage() {
  return <Form method="post" action="fffff" />;
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    loader: RootLoader,
    errorElement: <NotFound404 />,
    children: [
      { index: true, element: <HomePage />, loader: HomePageLoader },
      {
        path: "ranking",
        element: <RankingPage />,
        loader: RankingLoader,
      },
      {
        path: "ranking-list/:id",
        element: <RankingPageList />,
        // loader: RankingPageLoader,

      },
      {
        path: "under-construction",
        element: <WorkInProgress />,
        //loader: shopSummaryLoader
      },
      {
        path: "login-register",
        element: <LoginRegister />,
        //loader: page1Loader,
      },
      {
        path: "forgot-password",
        element: <ForgotPassword />,
        //loader: page1Loader,
      },
      {
        path: "event-list",
        element: <EventsPage />,
      },
      {
        path: "list-association",
        element: <ListDistrict />,
        loader: listLoader,
      },
      {
        path: "district",
        element: <DistrictHome />,
        loader: DistrictHomeLoader,
      },
      {
        path: "district/associations/:id",
        element: <DistrictAssociations />,
        loader: DistrictAssociationsLoader,
      },
      {
        path: "team/user-admin/:id",
        element: <AssociationDetails />,
        loader: associationLoader,
      },
      {
        path: "team/facilities/:id",
        element: <FacilitiesHome />,
        loader: FacilitiesHomeLoader,
      },
      {
        path: "team/facilities/:association_id/facility/:facility_id",
        element: <FacilityDetails />,
        loader: FacilityDetailsLoader,
      },
      {
        path: "team/facilities/:association_id/facility/:facility_id/edit",
        element: <FacilityForm />,
        loader: FacilityFormLoader,
      },
      {
        path: "test-documents",
        element: <TestDocuments />
      },
      {
        path: "test-page",
        element: <TesterPage />
      },
      {
        path: "get-license",
        element: <LicenseShop />
      },
      {
        path: "step-license",
        element: <LicenseStepper />,
        loader: LicenseShopLoader
      },
      {
        path: "license-summary",
        element: <ShopSummary />,
        //loader: shopSummaryLoader
      },
      {
        path: "process-payment",
        element: <PaymentProcess />,
        //loader: shopSummaryLoader
      },
      {
        path: "payment-error",
        element: <PaymentError />,
        //loader: shopSummaryLoader
      },
      {
        path: "successful-payment",
        element: <PaymentSuccessful />,
        //loader: shopSummaryLoader
      },
      {
        path: "search-users",
        element: <SearchUsers />,
        loader: searchUsersLoader
      },
      {
        path: "edit-user/:id?",
        element: <EditUser />,
        loader: editUserLoader,
      },
      {
        path: "user-details/:id?",
        element: <UserDetails />,
        loader: userLoader
      },
      {
        path: "event-overview/:id",
        element: <EventOverview />,
        loader: EventOverviewLoader,
      },
      {
        path: "event-anmalan/:id",
        element: <EventAnmalan />,
        loader: EventAnmalanLoader
      },

      {
        path: "system-settings",
        element: <SettingsHome />,
        //loader: EventOverviewLoader,
      },
      {
        path: "event-settings",
        element: <EventSettingsRoot />,
        //loader: EventSettingsRootLoader,
        children: [
          { index: true, element: <EventSettingsHome /> },
          {
            path: "categories",
            //element: <h1>fsfsfs</h1>
            element: <EventCategories />,
            loader: EventCategoriesLoader,

          },
          {
            path: "sportgrenar-klasser",
            //element: <h1>fsfsfs</h1>
            element: <EventSettingsDiscipline />,
            //loader: EventCategoriesLoader,

          },
          {
            path: "new",
            element: <EventNew />,
            //loader: EventNewLoader,
          },
        ],
      },
      {
        path: "events",
        element: <EventsRoot />,
        loader: EventsRootLoader,
        children: [
          { index: true, element: <EventsHome />, loader: EventsHomeLoader },
          {
            path: "new-edit/:id",
            element: <EventNew />,
            // action: EventNewAction,
            // loader: EventNewLoader,
          },
          {
            path: "new-edit-evenemang/:id",
            element: <EventNewEvenemang />,
            // action: EventNewAction,
            // loader: EventNewLoader,
          },

        ],
      },
      {
        path: "event/:id",
        element: <EventRoot />,
        loader: EventRootLoader,
        children: [
          {
            index: true,
            element: <EventOverviewAdmin />,
            loader: EventOverviewAdminLoader
          },
          {
            path: "edit",
            element: <EventNew />,
            // action: EventNewAction,
            // loader: EventNewLoader,
          },
          {
            path: "edit-stepper",
            element: <EventEditStepper />,
            children: [
              {
                index: true,
                element: <EventNew />,
                // action: EventNewAction,
                // loader: EventNewLoader,
              },
              {
                path: "start",
                element: <EventNew />,
                // action: EventNewAction,
                // loader: EventNewLoader,
              },
              {
                path: "gren-klass",
                element: <EventEditSportgrenar />,
              },
              {
                path: "plats",
                element: <h1>plats</h1>,
              },
              {
                path: "betalning",
                element: <EventEditBetalning />,
              },
              {
                path: "dokument",
                element: <EventEditDokument />,
              },
              {
                path: "lag",
                element: <EventEditLag />,
              },
              {
                path: "extra",
                element: <EventEditExtra />,
              },

            ]
          },
          {
            path: "gren-klass",
            element: <EventEditSportgrenar />,
            // loader: EventEditSportgrenarLoader,
          },
          {
            path: "betalning",
            element: <EventEditBetalning />,
          },
          {
            path: "edit-stepper-2",
            element: <EventEditStepper2 />,
          },

        ],
      },
      {
        path: "/team",
        element: <TeamHome />,
        loader: TeamHomeLoader,
        /* children: [
          { index: true, element: <TeamHome /> },
          {
            path: "overview",
            element: <TeamHome />,
            loader: TeamHomeLoader,
          },  
        ],   */
      },
      {
        path: "/distrikt",
        element: <DistriktHome />,
        loader: DistriktHomeLoader,
        /* children: [
          { index: true, element: <TeamHome /> },
          {
            path: "overview",
            element: <TeamHome />,
            loader: TeamHomeLoader,
          },  
        ],   */
      },
      {
        path: "/gren",
        element: <GrenHome />,
        loader: GrenHomeLoader,
        /* children: [
          { index: true, element: <TeamHome /> },
          {
            path: "overview",
            element: <TeamHome />,
            loader: TeamHomeLoader,
          },  
        ],   */
      },
      {
        path: "/settings",
        element: <SettingsRoot />,
        loader: SettingsRootLoader,
        children: [
          { index: true, element: <SettingsHome /> },
          {
            path: "overview",
            element: <SettingsHome />,
            //loader: EventOverviewLoader,
          },
        ],
      },
    ],
  },
]);



function App() {
  // const [basicOpen, setBasicOpen] = useState(true);
  console.log(process.env.NODE_ENV, window.location.protocol, window.location.hostname);
  return (

    <RouterProvider router={router} />

  );
}

export default App;