import {    
  MDBTable, 
  MDBTableHead, 
  MDBTableBody
 } from 'mdb-react-ui-kit';

export default function UserRoles(user){

	const roles = user.roles
  
  if(roles.length > 0){
    const rolesList = roles.map((role,index) => {
      return <tr key={index}>
							<td><p className='mb-1'>{role.name}</p></td>
							<td><p className='mb-1'>{role.description} </p></td>
								<td><p className='mb-1'>
									{role.forening_name ? role.forening_name : null} 
									{role.distrikt_name ? role.distrikt_name : null}
									{role.gren_name ? role.gren_name : null}
								</p></td>
							<td><p className='mb-1'>{role.id}</p></td>
							<td><p className='mb-1'>{role.status}</p></td>
            </tr>
    });
    return <div>
          <MDBTable align='middle'>
            <MDBTableHead>
              <tr>
                <th scope='col'>Namn</th>
								<th scope='col'>Beskrivning</th>
								<th scope='col'>Administratörsområde</th>
								<th scope='col'>ID</th>
								<th scope='col'>Status</th>
              </tr>
            </MDBTableHead>
            <MDBTableBody>{rolesList}</MDBTableBody>
          </MDBTable>
          </div>
          ;
  }

  return <p align='middle'>Användaren har inga roller kopplad till sig.</p>;
  
}