import { useNavigate } from "react-router-dom";
import {    
  MDBBtn,
  MDBTable, 
  MDBTableHead, 
  MDBTableBody,
  MDBBadge
 } from 'mdb-react-ui-kit';

export default function LicenseTable(user){

	const licenses = user.licenser
  
  if(licenses.length > 0){
    const navigate = useNavigate();
    const licenseList = licenses.map(license => {
      var colorStatus = "warning"
      if(license.status === "ACTIVE"){colorStatus = "success"}
      return <tr key={license.id}>
              <td><p className='mb-1'>{license.lic_name}</p></td>
              <td><p className='mb-1'>{license.gren_name}</p></td>
              <td><p className='fw-bold mb-1'>{license.start_date}</p></td>
              <td><p className='fw-bold mb-1'>{license.end_date}</p></td>
              <td>
                <MDBBadge color={colorStatus} pill>
                	{license.status}
                </MDBBadge>
              </td>
              <td>
                <MDBBtn color='link' rounded size='sm'>
                  Edit
                </MDBBtn>
              </td>
            </tr>
    });
    return <>
          <MDBTable align='middle'>
            <MDBTableHead>
              <tr>
                <th scope='col'>Namn</th>
                <th scope='col'>Gren/diciplin</th>
                <th scope='col'>Startdatum</th>
                <th scope='col'>Slutdatum</th>
                <th scope='col'>Status</th>
                <th scope='col'>Ändra</th>
              </tr>
            </MDBTableHead>
            <MDBTableBody>{licenseList}</MDBTableBody>
          </MDBTable>
          <MDBBtn color='primary' rounded size='sm' onClick={() => {navigate("/step-license",{state: [user]})}}>Köp licens</MDBBtn>
          </>
          ;
  }

  return <p align='middle'>Inga licenser finns att visa. Klicka <a href="/step-license" className="text-reset"><strong>här</strong></a> för att köpa licens.</p>;
  
}