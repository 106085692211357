import React from "react";
import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBContainer,
  MDBInput,
  MDBRow,
  MDBCol,
} from "mdb-react-ui-kit";
import {
  //Outlet,
  //NavLink,
  Link,
  //useLoaderData,
  //Form,
  //redirect,
  //useNavigation,
  //useSubmit,
} from "react-router-dom";

const ForgotPassword = () => (
  <MDBContainer className="pt-5">
    <MDBRow className="d-flex justify-content-center">
      <MDBCol md="6">
        <MDBCard className="text-center">
          <MDBCardHeader className="p-4">
            <h5 className="mb-0">
              <strong>Glömt lösenord</strong>
            </h5>
          </MDBCardHeader>
          <MDBCardBody className="p-4">
            <p className="mb-4">
              Ange email/licensnr och vi kommer att skicka en länk för att nollställa ditt lösenord
            </p>

            <form action="" className="mb-4">
              <MDBInput
                type="email"
                id="emailInput"
                className="mb-4"
                label="Email"
              />

              <MDBInput
                type="licens"
                id="licensInput"
                className="mb-4"
                label="Licensnummer"
              />

              <MDBBtn type="button">Begär lösenord</MDBBtn>
            </form>
            <div className="d-flex justify-content-between align-items-center mb-2">
              <u>
                <Link className="link" as={Link} to="/login-register">Tillbaka till inloggning</Link>
              </u>
            </div>
          </MDBCardBody>
        </MDBCard>
      </MDBCol>
    </MDBRow>
  </MDBContainer>
);

export default ForgotPassword;
